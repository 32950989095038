import React, { useEffect, useState } from 'react';
import {
  Button,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Snackbar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Alert } from '@mui/material';
import { Edit } from '@mui/icons-material';
import axios from 'axios';

// Importação dos estilos criados com styled-components
import {
  Container,
  Title,
  AddButton,
  GuestToggleWrapper,
  SnackbarMessage,
  LoaderWrapper,
  TableWrapper,
  ActionButton,
  DialogContentWrapper,
  StyledTextField,
} from './ConfigurationManagerStyles';
import api from '../../api';

const ConfigurationManager = () => {
  const [configurations, setConfigurations] = useState([]);
  const [peladaActive, setPeladaActive] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingConfig, setEditingConfig] = useState(null);
  const [newConfig, setNewConfig] = useState({ key: '', value: '', description: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchConfigurations();
    fetchPeladaActive();
  }, []);

  const fetchConfigurations = async () => {
    try {
      const response = await api.get('/getConfigurations');
      setConfigurations(response.data.configurations);
    } catch (error) {
      console.error('Error fetching configurations:', error);
      setSnackbarMessage('Erro ao buscar configurações');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchPeladaActive = async () => {
    try {
      const response = await api.get('/getPeladaActive');
      setPeladaActive(response.data.data);
    } catch (error) {
      console.error('Error fetching pelada active:', error);
      setSnackbarMessage('Erro ao buscar pelada ativa');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrUpdateConfig = async () => {
    try {
      const url = '/configuration';
      const method = editingConfig ? 'edit' : 'create';
      console.log(editingConfig)
      setNewConfig({...newConfig, action:method});
      console.log(newConfig)

      const payload = editingConfig ? { ...newConfig, id: editingConfig._id, action:method} : { ...newConfig, action:method};
      console.log(payload)
     
      await api.post(url,payload)

      setSnackbarMessage(editingConfig ? 'Configuração atualizada' : 'Configuração adicionada');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchConfigurations();
      setDialogOpen(false);
      setNewConfig({ key: '', value: '', description: '' });
      setEditingConfig(null);
    } catch (error) {
      console.error('Error saving configuration:', error);
      setSnackbarMessage('Erro ao salvar configuração');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditConfig = (config) => {
    setEditingConfig(config);
    setNewConfig({ key: config.key, value: config.value, description: config.description });
    setDialogOpen(true);
  };

  const handleOpenGuestToggle = async () => {
    try {
      setLoading(true);
      alert(peladaActive._id)
      await api.post('/openGuest', { peladaId:peladaActive._id });
      setSnackbarMessage('Convidados Liberados');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchPeladaActive();
    } catch (error) {
      console.error('Error updating pelada active:', error);
      setSnackbarMessage('Erro ao atualizar pelada ativa');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Container>
      <Title>Gerenciar Configurações</Title>
      
      {loading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <>
          <AddButton onClick={() => setDialogOpen(true)}>
            Adicionar Configuração
          </AddButton>

          <TableWrapper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Chave</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {configurations.map((config) => (
                    <TableRow key={config._id}>
                      <TableCell>{config.key}</TableCell>
                      <TableCell>{config.value}</TableCell>
                      <TableCell>{config.description}</TableCell>
                      <TableCell>
                        <ActionButton onClick={() => handleEditConfig(config)}>
                          <Edit />
                        </ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapper>

          {peladaActive && (
            <GuestToggleWrapper>
              <FormControlLabel
                control={
                  <Switch
                    checked={peladaActive.openGuest}
                    onChange={handleOpenGuestToggle}
                  />
                }
                label="Permitir Convidados"
              />
            </GuestToggleWrapper>
          )}

          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{editingConfig ? 'Editar Configuração' : 'Adicionar Configuração'}</DialogTitle>
            <DialogContent>
              <DialogContentWrapper>
                <StyledTextField
                  autoFocus
                  placeholder="Chave"
                  value={newConfig.key}
                  onChange={(e) => setNewConfig({ ...newConfig, key: e.target.value })}
                  disabled={!!editingConfig}
                />
                <StyledTextField
                  placeholder="Valor"
                  value={newConfig.value}
                  onChange={(e) => setNewConfig({ ...newConfig, value: e.target.value })}
                />
                <StyledTextField
                  placeholder="Descrição"
                  multiline
                  value={newConfig.description}
                  onChange={(e) => setNewConfig({ ...newConfig, description: e.target.value })}
                />
              </DialogContentWrapper>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color="secondary">
                Cancelar
              </Button>
              <Button onClick={handleAddOrUpdateConfig} color="primary">
                {editingConfig ? 'Atualizar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              sx={{ width: '100%' }}
            >
              <SnackbarMessage>
                {snackbarMessage}
              </SnackbarMessage>
            </Alert>
          </Snackbar>
        </>
      )}
    </Container>
  );
};

export default ConfigurationManager;
