import Header from '../../components/Header';
import Grupo from './Grupo';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';


const confrontos = [
  {
    nomeGrupo: 'Grupo A',
    confrontos: [
      {
        horario: '15:00',
        time1: 'PSG',
        time2: 'Manchester City',
        goleiro1: 'A Definir',
        goleiro2: 'Igor',
      },
      {
        horario: '15:36',
        time1: 'Bayern de Munique',
        time2: 'PSG',
        goleiro1: 'A Definir',
        goleiro2: 'A Definir',
      },
      {
        horario: '16:12',
        time1: 'Manchester City',
        time2: 'Bayern de Munique',
        goleiro1: 'A Definir',
        goleiro2: 'Igor',
      },
    ],
  },
  {
    nomeGrupo: 'Grupo B',
    confrontos: [
      {
        horario: '15:18',
        time1: 'Real Madrid',
        time2: 'Barcelona',
        goleiro1: 'A Definir',
        goleiro2: 'Igor',
      },
      {
        horario: '15:54',
        time1: 'Barcelona',
        time2: 'Liverpool',
        goleiro1: 'A Definir',
        goleiro2: 'A Definir',
      },
      {
        horario: '16:30',
        time1: 'Liverpool',
        time2: 'Real Madrid',
        goleiro1: 'A Definir',
        goleiro2: 'A Definir',
      },
    ],
  },
  {
      nomeGrupo: 'Semifinais',
      confrontos: [
        {
          time1: 'Vencedor Grupo A (A Definir) TIME:',
          time2: 'Segundo Grupo B (A Definir) TIME:',
          horario: '16:48',
        },
        {
          time1: 'Vencedor Grupo B (A Definir) TIME:',
          time2: 'Segundo Grupo A (Igor) TIME:',
          horario: '17:06',
        },
      ],
    },
    {
      nomeGrupo: 'Final',
      confrontos: [
        {
          time1: 'Vencedor Semifinal 1 (Goleiro Vencedor) TIME:',
          time2: 'Vencedor Semifinal 2 (Goleiro Vencedor) TIME:',
          horario: '17:24',
        },
      ],
    },
];

const Jogos = () => {

    const navigate = useNavigate();
  return (
    <div className="App">
       <Header/>
       <Button variant="contained" onClick={()=>navigate('/')}>Voltar</Button>    
      {confrontos.map((grupo, index) => (
        <Grupo
          key={index}
          nomeGrupo={grupo.nomeGrupo}
          confrontos={grupo.confrontos}
        />
      ))}
    </div>
  );
}

export default Jogos;
