import React from 'react';

import Routes from "./routes";

const App = () => {
  return (
    <Routes />
  );
};

export default App;
