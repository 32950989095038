

import { ConfrontoContainer, Escudo, ConfrontoInfo, Horario, Placar } from './styles';

function Confronto({ horario, time1, time2, goleiro1, goleiro2 }) {
  const escudoTime1 = `/times/${time1.replace(/ /g, '_')}.png`;
  const escudoTime2 = `/times/${time2.replace(/ /g, '_')}.png`;

  return (
    <ConfrontoContainer>
      <Escudo src={escudoTime1} alt={time1} />
      <Escudo src={escudoTime2} alt={time2} />
      <ConfrontoInfo>
        <Horario>{`Horário: ${horario}`}</Horario>
        <p>{`${time1} vs. ${time2}`}</p>
        <p>{`Goleiro: ${goleiro1} vs. ${goleiro2}`}</p>
        <h3>2 tempos de 8 minutos</h3>
      </ConfrontoInfo>
    </ConfrontoContainer>
  );
}

// Componente para representar um grupo de confrontos
function Grupo({ nomeGrupo, confrontos }) {
    confrontos.sort((a, b) => a.horario.localeCompare(b.horario));
  return (
    <div className="grupo">
      <h2>{nomeGrupo}</h2>
      {confrontos.map((confronto, index) => (
        <Confronto
          key={index}
          time1={confronto.time1}
          time2={confronto.time2}
          goleiro1={confronto.goleiro1}
          goleiro2={confronto.goleiro2}
          horario={confronto.horario}
        />
      ))}
    </div>
  );
}

export default Grupo;
