import styled from 'styled-components';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { DataGrid } from '@mui/x-data-grid';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
 
  background-image: url('/background_app.jpeg'); /* Caminho relativo a partir de public */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
`;

export const StyledCard = styled(CardContent)`
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 90%;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-right: 50px;
`;

export const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnHeaders {
    background-color: #1976d2;
    color: white;
  }
  .positive {
    color: green;
  }
  .negative {
    color: red;
  }
`;

export const DialogTitleStyled = styled.h2`
  margin: 0;
  padding: 16px;
  background-color: #1976d2;
  color: white;
`;

export const DialogContentStyled = styled.div`
  padding: 16px;
  width: 300px;
`;

export const DialogActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;
