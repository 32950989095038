// src/utils/shareUtils.js
export const generatePlayerListMessage = (players) => {
    let message = 'Lista de Jogadores em Concentração:\n\n';
    players.forEach((player, index) => {
      message += `${index + 1}. ${player.user_name}\n`;
    });
    return message;
  };

  // src/utils/shareUtils.js (continuation)
export const generateWhatsAppLink = (players) => {
    const message = generatePlayerListMessage(players);
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/?text=${encodedMessage}`;
  };