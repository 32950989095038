import React from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import styled from 'styled-components';
import capa from '../images/logo_rfc.jpg.png'
import Header from '../components/Header';
import PoliticasDeUso from '../components/politicasUso';



const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #B0C4DE ;
`;

const Image = styled.img`
  width: 100%;
  max-width: 800px;
`;

const Description = styled.p`
  margin-top: 1rem;
  text-align: left;
  font-size: 24px;
  background-color: beige;
  width: 80%;
  padding: 10px;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
  
`;

const Politicas = () => {

  return (
    <div>
      <Header></Header>
      <Body>
        <Image
          src={capa}  // Substitua pelo URL da sua imagem
          alt="Riviera FC"
        />
        <Description>
       
            <PoliticasDeUso></PoliticasDeUso>
        </Description>
      </Body>
    </div>
  );
};

export default Politicas;
