import React, { useEffect, useState } from "react";
import api from "../../api";
import PlayArrived from "./PlayArrived";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Header from "../../components/Header";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Game from "../game/Game";
import "./PlayArrived.css";
import { DialogContentStyled } from "../dashbord/Styles";
import EstatisticasJogadores from "../game/EstatisticasJogadores";

const PlayArrivedPage = () => {
  const [confirmedPlayers, setConfirmedPlayers] = useState([]);
  const [arrivedPlayers, setArrivedPlayers] = useState([]);
  const [confirmArrivedDialogOpen, setConfirmArrivedDialogOpen] =
    useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [playAutenthic, setPlayAutenthic] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [userLogado, setUserLoagado] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [anfitriao, setAnfitriao] = useState({});
  const [severity, setSeverity] = useState("success");
  const [openMessage, setOpenMessage] = useState(false);
  const [pelada, setPelada] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [guestName, setGuestName] = useState(""); // Nome do convidado
  const [guestDialogOpen, setGuestDialogOpen] = useState(false);
  const [paramDistance, setParamDistance] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [gameData, setGameData] = useState(null);

  const confirmArrived = async () => {
    const { user } = playAutenthic;
    await checkLocationAndArrive({ userId: user, peladaId: pelada._id }, null);
    setConfirmArrivedDialogOpen(false);
  };

  const playArrived = async (payload, player) => {
    setLoading(true);
    try {
      const response = await api.post("playArrived", payload);
      if (response.status === 200) {
        await getPeladaActive();
        setMessage("Jogador Adicionado.");
        setSeverity("success");
        setOpenMessage(true);
      }
    } catch (error) {
      console.error("Error marking arrival:", error);
      setMessage(error.response.data.error || "Erro ao adicionar jogador.");
      setSeverity("error");
      setOpenMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const getConfiguration = async (keyParam) => {
    setLoading(true);
    try {
      const response = await api.get(`/getConfiguration?key=${keyParam}`);
      if (response.status === 200) {
        if (response.data.configuration != null) {
          setParamDistance(response.data.configuration.value);
        }
      }
    } catch (error) {
      console.error("Error get configuration:", error);
      setMessage("Erro obter configurações.");
      setSeverity("error");
      setOpenMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const removePlayer = async (payload) => {
    setLoading(true);
    try {
      const response = await api.post("deleteArrived", payload);
      await getPeladaActive();
      setMessage("Jogador removido.");
      setSeverity("success");
      setOpenMessage(true);
    } catch (error) {
      console.error("Error removing player:", error);
      setMessage(error.response.data.error || "Erro ao remover jogador.");
      setSeverity("error");
      setOpenMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const getPeladaActive = async () => {
    setLoading(true);
    try {
      const response = await api.get("getPeladaActive");
      if (response.data.data != null) {
        const { arrivedPlayers, confirmedPlayers } = response.data.data;
        const filteredConfirmedPlayers = confirmedPlayers.filter(
          (confirmedPlayer) =>
            !arrivedPlayers.some(
              (arrivedPlayer) =>
                arrivedPlayer.user.toString() ===
                  confirmedPlayer.user.toString() &&
                (!confirmedPlayer.guestId ||
                  arrivedPlayer.guestId === confirmedPlayer.guestId)
            )
        );
        setArrivedPlayers(arrivedPlayers);
        setConfirmedPlayers(filteredConfirmedPlayers);
        setPelada(response.data.data);
        const pelada = response.data.data;
        console.log(pelada);

        const userConfirmed = filteredConfirmedPlayers.filter(
          (confirmedPlayer) =>
            !confirmedPlayer.guest &&
            confirmedPlayer.user.toString() === userLogado._id
        );
        if (userConfirmed.length > 0) {
          setPlayAutenthic(userConfirmed[0]);
          setConfirmArrivedDialogOpen(true);
        } else {
          setMessage("Você já confirmou sua chegada!");
          setOpenMessage(true);
          if (
            pelada.listaDeEspera !== undefined &&
            pelada.listaDeEspera.length > 0
          ) {
            setTabValue(1);
          }
        }
      } else {
        setMessage("Não há pelada aberta");
        setOpenMessage(true);
      }
    } catch (error) {
      console.error("Error fetching pelada:", error);
      setMessage("Ocorreu um erro inesperado: " + error.message);
      setSeverity("error");
      setOpenMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddGuest = async (user) => {
    if (guestName === "") {
      createMessage("Nome do convidado obrigatório", "error");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        userId: user._id,
        peladaId: pelada._id,
        nameGuest: guestName,
      };

      await api.post("addGuest", payload);
      setMessage("Convidado adicionado com sucesso.");
      setSeverity("success");
      setOpenMessage(true);
      setGuestDialogOpen(false); // Fechar o diálogo após adicionar
      await getPeladaActive(); // Atualizar a lista
    } catch (error) {
      console.error("Error adding guest:", error);
      setMessage(error.response.data.error || "Erro ao adicionar convidado.");
      setSeverity("error");
      setOpenMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const createMessage = (mensagem, severity) => {
    setMessage(mensagem);
    setSeverity(severity);
    setOpenMessage(true);
  };

  const closeGuestDialog = () => setGuestDialogOpen(false);

  // Coordenadas do local de concentração (latitude e longitude)
  const targetLatitude = -8.126343448577488; // exemplo
  const targetLongitude = -34.90830565645442; // exemplo

  // Função para calcular a distância entre duas coordenadas em metros
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Raio da Terra em metros
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distância em metros
  };

  const checkLocationAndArrive = async (payload, player) => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const userLatitude = position.coords.latitude;
          const userLongitude = position.coords.longitude;

          const distance = calculateDistance(
            userLatitude,
            userLongitude,
            targetLatitude,
            targetLongitude
          );

          const formattedDistance = Math.round(distance).toFixed(0);
          const distanceParam =
            paramDistance == null ? 40 : Math.round(paramDistance).toFixed(0);

          // Convertendo formattedDistance para número
          const formattedDistanceNum = parseInt(formattedDistance, 10);
          const distanceParamNum = parseInt(distanceParam, 10);

          if (formattedDistanceNum <= distanceParamNum) {
            // Chamar a função para registrar a chegada
            await playArrived(payload, player);
          } else {
            setMessage(
              `Você está muito longe do local de concentração dos jogadores. Distância: ${formattedDistance} Metros`
            );
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
          }
        },
        (error) => {
          console.error(error);
          setMessage("Erro ao obter localização.");
          setSeverity("error");
          setOpenMessage(true);
          setLoading(false);
        }
      );
    } else {
      setMessage("Geolocalização não é suportada neste navegador.");
      setSeverity("error");
      setOpenMessage(true);
    }
  };

  useEffect(() => {
    getPeladaActive();
    getConfiguration("PERIMETRO_ARRIVED");
    fetchGameActive()
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchGameActive = async () => {
    setLoading(true);
    try {
      const response = await api.get("getGameActive");
      console.log("API Response:", response.data);

      if (
        response.data.partida &&
        Object.keys(response.data.partida).length !== 0
      ) {
        setGameData({ ...response.data }); 
      }
    } catch (error) {
      console.error("Error fetching active game:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={() => setOpenMessage(false)}
      >
        <MuiAlert
          onClose={() => setOpenMessage(false)}
          severity={severity}
          elevation={6}
          variant="filled"
        >
          {message}
        </MuiAlert>
      </Snackbar>
      <Backdrop style={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="divButton">
        <button
          style={{ margin: "20px" }}
          onClick={() => setGuestDialogOpen(true)}
        >
          Adicionar Convidado
        </button>
      </div>
      <Dialog
        open={confirmArrivedDialogOpen}
        onClose={() => setConfirmArrivedDialogOpen(false)}
      >
        <DialogTitle>{playAutenthic.user_name}</DialogTitle>
        <DialogContentStyled>
          <p>Você chegou?</p>
        </DialogContentStyled>
        <DialogActions>
          <Button
            onClick={() => setConfirmArrivedDialogOpen(false)}
            color="secondary"
          >
            Não
          </Button>
          <Button onClick={() => confirmArrived(playAutenthic)} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={guestDialogOpen} onClose={closeGuestDialog}>
        <DialogTitle>Adicionar Convidado</DialogTitle>
        <DialogContentStyled>
          <p>
            Confirmar convidado? Uma cobrança de diária será lançada a sua
            conta.
          </p>
        </DialogContentStyled>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nome do Convidado"
            type="text"
            fullWidth
            value={guestName}
            onChange={(e) => setGuestName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeGuestDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => handleAddGuest(playAutenthic)} color="primary">
            Confirmar Convidado
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: "100%" }}>
        <Tabs value={tabValue} onChange={handleChangeTab} centered>
          <Tab label="Lista de Chegada" />
          <Tab label="Partidas" />
          <Tab label="Estatisticas" />
        </Tabs>
        {tabValue === 0 && (
          <PlayArrived
            confirmedPlayers={confirmedPlayers}
            arrivedPlayers={arrivedPlayers}
            playArrived={checkLocationAndArrive}
            removePlayer={removePlayer}
            play={pelada}
          />
        )}
        {tabValue === 1 && <Game />}
        {tabValue === 2 && <EstatisticasJogadores estatisticas={gameData.estatisticasJogadores} fetchGameActive={fetchGameActive} />}
      </Box>
    </>
  );
};

export default PlayArrivedPage;
