import React from 'react';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Card = styled.div`
  width: 200px; /* Aumentado para melhor visibilidade */
  height: 250px; /* Aumentado para acomodar mais informações */
  background-color: darkseagreen; /* Cor de fundo mais clara */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Usado para espaçamento entre os itens */
  margin: 10px;
  position: relative;
  padding: 15px; /* Adicionado padding para espaçamento interno */
  transition: transform 0.2s; /* Efeito de transição suave */
  
  &:hover {
    transform: scale(1.05); /* Efeito de zoom ao passar o mouse */
  }
`;

const ShirtNumber = styled.div`
  font-size: 3em;
  font-weight: bold;
  color: #333;
  margin-top: 10px; /* Espaço reduzido para melhor visualização */
`;

const PlayerName = styled.div`
  font-size: 1.5em; /* Aumentado para melhor legibilidade */
  font-weight: bold;
  color: #333;
  margin-bottom: 10px; /* Adicionado espaço abaixo do nome */
`;

const InfoText = styled.p`
  font-size: 0.9em; /* Tamanho de fonte ajustado */
  color:${(props) => props.pagou=='SIM'? '#666': 'red'};  /* Cor de texto mais suave */
  margin: 5px 0; /* Margem para espaçamento entre linhas */
`;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px; /* Espaço acima dos ícones */
`;

const PlayerCard = ({ player, onEdit, onDelete }) => {
  const playerName = player.nome.split(' ').slice(0, 2).join(' ');

  return (
    <Card>
      <PlayerName>{playerName}</PlayerName>
      <ShirtNumber>{player.numeroCamisa}</ShirtNumber>
      <InfoText pagou={player.pago}>Pagou: {player.pago=='SIM' ? "Sim" : "Não"}</InfoText>
      <InfoText pagou={player.festa} >Vai à festa: {player.festa=='SIM' ? "Sim" : "Não"}</InfoText>
      <InfoText pagou={player.tamanhoCamisa} >tamanho Camisa: {player.tamanhoCamisa}</InfoText>
      <InfoText>Convidado: {player.nomeConvidado}</InfoText>
      <IconContainer>
        <EditIcon onClick={() => onEdit(player)} style={{ cursor: 'pointer' }} />
        <DeleteIcon onClick={() => onDelete(player)} style={{ cursor: 'pointer' }} />
      </IconContainer>
    </Card>
  );
};

export default PlayerCard;