import React from 'react';
import styled from 'styled-components';
import capa from '../images/GRID_01.png'
import Header from '../components/Header';
import Squad from './squads/Squad'




const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #B0C4DE ;
`;

const Image = styled.img`
  width: 100%;
  max-width: 800px;
`;

const Description = styled.p`
  margin-top: 1rem;
  text-align: left;
  font-size: 24px;
  background-color: beige;
  width: 80%;
  padding: 10px;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
  
`;

const Home = () => {

  return (
    <div>
      <Header></Header>
      <Body>
        <Image
          src={capa}  // Substitua pelo URL da sua imagem
          alt="Apresentação"
        />
         {/* <Image
          src='/image/googlePlay.png'  // Substitua pelo URL da sua imagem
          alt="Apresentação"
        /> */}
         {/* <Image
          src=''  // Substitua pelo URL da sua imagem
          alt="Apresentação"
        /> */}
        <Description>
          A Pelada Riviera, localizada no condomínio Riviera Boa Viagem, é um verdadeiro marco de convivência e esportividade. Com uma história de seis anos de existência, essa tradição nasceu do entusiasmo e espírito esportivo dos moradores. Anualmente, a comunidade se reúne para participar do torneio que une os amantes do futebol em momentos de confraternização e diversão. O ambiente amigável e competitivo da Pelada Riviera proporciona uma oportunidade única para os peladeiros de todas as idades mostrarem suas habilidades e paixão pelo esporte. Ao longo dos anos, essa pelada se tornou mais do que apenas um jogo; é um evento que fortalece os laços entre os moradores e promove a camaradagem em torno do esporte que todos amam.
        </Description>
      </Body>
        <Squad></Squad>
    </div>
  );
};

export default Home;
