import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



const  PieChart = ({data})=> {
  return (<Pie data={data} />);
}

// const Pot = ({ potNum, playersInPot, onDrop, onPlayerRemove }) => {
//     return (
//       <PotContainer
//         onDragOver={(e) => e.preventDefault()}
//         onDrop={(e) => onDrop(e, potNum)}
        
//         qtdPlayers = {playersInPot.length}
//       >
//         <PotTitle>Pote {potNum} ({playersInPot.length})</PotTitle>
//         {playersInPot.map((player, index) => (
//           <PlayerInPot key={index}>
//             {player}
//             <Tooltip title="excluir">
//             <RemoveButton onClick={(e) => onPlayerRemove(e, potNum, player)}>X</RemoveButton>
  
//             </Tooltip>
//           </PlayerInPot>
//         ))}
//       </PotContainer>
//     );
//   };
  
  export default PieChart;
