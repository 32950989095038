import styled from 'styled-components';

export const PageWrapper = styled.div`
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom right, #3a3a3a, #1a1a1a);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('/background_app.jpeg'); /* Caminho relativo a partir de public */
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    align-items: center;
    max-width: 400px; /* Centraliza os itens em telas menores */
  }

`;

export const Container = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    max-width: 400px; /* Centraliza os itens em telas menores */
  }
`;

export const Title = styled.h2`
  color: black;
`;

export const Select = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

export const PlayersGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;