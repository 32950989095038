import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';



const Paragraph = styled.p`
  color: #666;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  color: #666;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Highlight = styled.strong`
  color: #e63946;
`;

const PageWrapper = styled.div`
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom right, #3a3a3a, #1a1a1a);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Adicionado para evitar overflow */
`;

const Container = styled.div`
  max-width: 600px;
  width: 100%; /* Ajustado para tela mobile */
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 15px;
    box-shadow: none;
    margin: 20px;
  }
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;

const Message = styled.p`
  margin-top: 20px;
  font-size: 1.2em;
  color: #333;
`;

const CopyButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ConfirmationPage = () => {
  const [showDialog, setShowDialog] = useState(false);
  const chavePix = '00020101021126900014br.gov.bcb.pix0136740ea0ee-40b5-4bf9-9df3-be0dd67419820228inscricao  6 torneio riviera5204000053039865802BR5922FERNANDO J DE M JUNIOR6006RECIFE62070503***6304A576';
  
  const handleCopyClick = () => {
    navigator.clipboard.writeText(chavePix);
    alert('Chave Pix copiada para a área de transferência!');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDialog(true);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <PageWrapper>
      <Container>
      <Image src="image/pix_celular.png" alt="Torneio de Futebol" />

        <Message>Para concluir sua inscrição, realize o pagamento utilizando o QR code abaixo:</Message>
        <Image src="image/pix_torneio.png" alt="QR Code" />
        {/* <Message>Chave Pix: {chavePix}</Message> */}
        <div></div>
        <CopyButton onClick={handleCopyClick}>Copiar Chave</CopyButton>
        <Paragraph><Highlight>Valor de Inscrição:</Highlight></Paragraph>
        <List>
          <ListItem>Torneio + Festa: R$ 160,00 (Jogador terá direito a uma camisa que será obrigatória para jogar)</ListItem>
          <ListItem>Apenas Torneio: R$ 100,00</ListItem>
          <ListItem>Acompanhante: R$ 120,00</ListItem>
        </List>
      </Container>

      <Dialog open={showDialog} onClose={handleCloseDialog}>
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <Message>
            O pagamento será validado e a inscrição concluída. Por favor, envie o comprovante para o organizador.
          </Message>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Ok</Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
};

export default ConfirmationPage;