import { useAuth } from '../AuthContext';
import { Navigate } from 'react-router-dom';

export function AuthMiddleware({ children }) {
  const { isAuthenticated } = useAuth();

  // Verifica se o usuário está autenticado
  if (!isAuthenticated) {
    // Se não estiver autenticado, redireciona para a página de login
    return <Navigate to="/login" />;
  }

  // Se estiver autenticado, permite o acesso à rota
  return children;
}
