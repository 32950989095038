import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom right, #3a3a3a, #1a1a1a);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const Info = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: left;
`;

const Heading = styled.h2`
  color: #333;
`;

const Paragraph = styled.p`
  color: #666;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  color: #666;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Highlight = styled.strong`
  color: #e63946;
`;

const ButtonContainer = styled.div`
  display: inline-block;
  padding: 15px 30px;
  margin-top: 20px;
  background-color: #e63946;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #d62828;
    transform: translateY(-5px);
  }
`;

const PresentationPage = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/confirmarInscricao');
  };

  return (
    <Container>
      <Image src="/image/capa_torneio.jpeg" alt="Torneio de Futebol" />
      <Info>
        <Heading>Data do Evento: 21/09/2024</Heading>
        <Paragraph><Highlight>Valor de Inscrição:</Highlight></Paragraph>
        <List>
          <ListItem>Torneio + Festa: R$ 160,00 (Jogador terá direito a uma camisa que será obrigatória para jogar)</ListItem>
          <ListItem>Apenas Torneio: R$ 100,00</ListItem>
          <ListItem>Acompanhante: R$ 120,00</ListItem>
        </List>
        <Paragraph>O torneio será composto por 6 times de 6 jogadores, formatado em 2 grupos de 3 times com semifinais e final.</Paragraph>
        <Heading>A Festa:</Heading>
        <Paragraph>A festa será all inclusive com chopp Plezier e buffet. Para animar, duas bandas que ainda serão contratadas.</Paragraph>
      </Info>
      <ButtonContainer onClick={handleRegisterClick}>
        Inscreva-se
      </ButtonContainer>
    </Container>
  );
};

export default PresentationPage;