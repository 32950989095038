import React, { useState } from 'react';
import styled from 'styled-components';
import PlayerSelector from './PlayerSelector';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Estilo para o diálogo
const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  color: white;

  &:hover {
    opacity: 0.8;
  }
`;

const SwapPlayersDialog = ({ isOpen, onClose, onConfirm, availablePlayers }) => {
  const [playerOut, setPlayerOut] = useState('');
  const [playerIn, setPlayerIn] = useState('');

  const handleConfirm = () => {
    if (playerOut && playerIn) {
      onConfirm(playerOut, playerIn);
    }
  };

  return (
    isOpen && (
        <ModalOverlay>
      <ModalContent>
      <DialogContainer>
        <h3>Trocar Jogadores</h3>
        <PlayerSelector
          players={availablePlayers}
          selectedPlayer={playerOut}
          onSelect={setPlayerOut}
          type={'Saída'}
        />
        <PlayerSelector
          players={availablePlayers}
          selectedPlayer={playerIn}
          onSelect={setPlayerIn}
          type={'Entrar'}

        />
        <ButtonContainer>
          <Button bgColor="#007bff" onClick={handleConfirm}>
            Confirmar
          </Button>
          <Button bgColor="#dc3545" onClick={onClose}>
            Cancelar
          </Button>
        </ButtonContainer>
      </DialogContainer>
      </ModalContent>
      </ModalOverlay>
    )
  );
};

export default SwapPlayersDialog;
