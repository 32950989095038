import React from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaSignOutAlt, FaHome } from 'react-icons/fa';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PlaceIcon from '@mui/icons-material/Place';
import styled from 'styled-components';



const HeaderStyle = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: white;
`;

const HeaderIcon = styled.div`
  margin-right: 1rem;
  font-size: 24px;
  cursor: pointer;
`;


const Header = () => {




  const { isAuthenticated, logout, userLogado } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <HeaderStyle>
      {isAuthenticated ? (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            MENU
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >

            <MenuItem onClick={() => navigate('/user/aprovacao')}>
              {userLogado.perfil == 'Admin' || userLogado.perfil == 'Diretor' ? (
                <HeaderIcon>
                  <SupervisorAccountIcon />Administração
                </HeaderIcon>) : (<></>)}
            </MenuItem>
            <MenuItem onClick={() => navigate('/adminJogadores')}>
              {userLogado.perfil == 'Admin' || userLogado.perfil == 'Diretor' ? (
                <HeaderIcon>
                  <SupervisorAccountIcon />Torneio
                </HeaderIcon>) : (<></>)}
            </MenuItem>

            <MenuItem onClick={() => navigate('/')}>
              <HeaderIcon >
                <FaHome />Home
              </HeaderIcon>
            </MenuItem>

            <MenuItem>
              <HeaderIcon onClick={() => navigate('/ordem-chegada')}>
                <PlaceIcon />Ordem Chegada
              </HeaderIcon>
            </MenuItem>
            <MenuItem>
              <HeaderIcon onClick={() => navigate('/ranking')}>
                <PlaceIcon />Eficiência Jogadores
              </HeaderIcon>
            </MenuItem>
            

            <MenuItem>
              <HeaderIcon onClick={() => navigate('/painel')}>
                <AssessmentIcon />Painel
              </HeaderIcon>
            </MenuItem>
           


            <MenuItem>
              <HeaderIcon onClick={() => navigate('/votacao')}>
                <LocalDrinkIcon />Potes
              </HeaderIcon>
            </MenuItem>

            <MenuItem>
              <HeaderIcon onClick={handleLogout}>
                <FaSignOutAlt />Sair
              </HeaderIcon>
            </MenuItem>
          </Menu>

        </>

      ) : (



        <HeaderIcon onClick={() => navigate('/login')}>
          <FaUserCircle />
          Login
        </HeaderIcon>
      )}
    </HeaderStyle>
  );
};

export default Header;
