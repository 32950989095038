
// TimesPageStyles.js
import styled from 'styled-components';

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: left;
  gap: 20px;
  text-align: center;
  max-width: 2000px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Time = styled.div`
display: flex;
flex-direction: row;
/* flex-wrap: wrap; */
  border: 1px solid #ccc;
  padding: 10px;
  /* max-width: 2000px; */
  align-items: center;
  background-color: whitesmoke;
    width: min-content;

    @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TimeTitle = styled.h2`
  margin: 0;
  padding: 10px;
  color: black;
`;

export const TimeJogadores = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
  /* justify-content: center; */
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TimeImage = styled.img`
  max-width: 300px; /* Defina o tamanho máximo desejado para a imagem do time */
  display: block; /* Isso centraliza a imagem horizontalmente */
  margin: 0 auto; /* Isso centraliza a imagem verticalmente */
  max-height: 200px;
  margin-left: 50px;
  margin-right: 20px;
`;




