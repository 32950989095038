import React from 'react';




const Jogador = ({ jogador, onDragStart }) => {
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, jogador)} 
      style={{cursor: 'pointer'}}
    >
      
      Arraste
    </div>
  );
};

export default Jogador;
