import React from 'react';
import styled from 'styled-components';


const H2 = styled.h2`
  color: black;
 
`;

function Regras() {
  return (
    <div>
      <h1>Regras</h1>

      <H2>1 - Tempo de Partida</H2>
      <p>
        1.1 As partidas têm duração prevista de 10 minutos. A primeira partida
        terá duração de 15 minutos quando houver apenas 8 atletas (de linha) no
        período de concentração até o apito inicial da primeira partida.
      </p>
      <p>
        1.2 O tempo da partida é corrido e SOMENTE será suspenso em razão de
        atendimento ao atleta em campo.
      </p>

      <H2>2 - Formação das Equipes</H2>
      <p>
        2.1  <p>Para garantir uma experiência justa e organizada, utilizamos um sistema de prioridades para determinar a ordem dos jogadores. As prioridades são aplicadas na seguinte ordem:</p>

        <H2>1. Tipo de Jogador:</H2>
        <ul>
          <li>Jogadores que são mensalistas têm prioridade sobre outros tipos de jogadores, como diaristas e convidados.</li>
          <li >Exceção: Se um mensalista chegar após as 20h no dia do jogo, ele não tem prioridade sobre os que chegaram antes das 20h, mesmo que sejam de outros tipos.</li>
        </ul>

        <H2>2. Devedor:</H2>
        <ul>
          <li>Jogadores em dia com a mensalidade têm prioridade sobre aqueles que estão devendo.</li>
          <li>Exceção: Se ambos os jogadores sendo comparados são diaristas, a regra de devedor não se aplica.</li>
        </ul>

        <H2>3. Hora de Confirmação:</H2>
        <ul>
          <li>Jogadores que confirmaram sua presença antes das 17h no dia do jogo têm prioridade sobre aqueles que confirmaram após esse horário.</li>
          <li >Importante: Esta regra só se aplica se a confirmação foi feita no mesmo dia da pelada.</li>
        </ul>

        <H2>4. Ordem de Chegada:</H2>
        <ul>
          <li>Se todas as outras condições forem iguais, a ordem de chegada ao local do jogo é usada como critério final. Quem chega primeiro tem prioridade sobre quem chega depois.</li>
          <li>Jogadores envolvidos em atividades esportivas anteriores não podem marcar chegada até está presente no local da concentração com o devido material de jogo.</li>
        
        </ul>

        <p></p>
      </p>
      <p>
        PARÁGRAFO PRIMEIRO: Os atletas da primeira partida podem ser escolhidos
        por sorteio desde que sejam respeitados os critérios acima para formação
        das equipes.
      </p>
      <p>
        PARÁGRAFO SEGUNDO: O mensalista terá sempre prioridade em relação a um
        diarista.
      </p>
      <p>
        2.2 A ordem de chegada deve prevalecer sempre para composição da equipe
        participante na partida subsequente, portanto não existe previsão para
        decisão por pênaltis caso alguém esteja na fila de espera.
      </p>
      <p>
        PARÁGRAFO PRIMEIRO: 
        Em caso de necessidade de completar o próximo time a jogar, 
        deve-se optar por um jogador que esteja saindo de campo. 
        Se houver jogadores com a mesma quantidade de jogos, 
        a ordem de chegada será usada para determinar quem tem a 
        preferência para completar o time, caso contrário o 
        jogador que tem menos jogos completará o time.
       
      </p>
      <p>
        PARÁGRAFO SEGUNDO: Caso tenha ao menos um time completo na lista de
        espera, <b>os atletas que chegarem atrasados não poderão jogar a próxima
          partida</b>. O atleta deve formar o próximo time após a equipe da vez, o
        complemento dos demais atletas do seu time deve seguir a regra acima.
      </p>
      <p>
        2.3 Em caso de empate ou segunda vitória consecutiva de um dos times,
        sempre saem os dois times, ficando a retornar na partida seguinte o time
        que teve a segunda vitória e no caso de empate os times devem entrar por
        ordem de chegada conforme informado item 2.2.
       <b> Observação: O time que ganhou duas vezes vai para fila de espera se existir pelo menos
        5 atletas na fila de espera. 
        Caso tenha menos de 5 atletas o time que venceu continua em campo.</b>
      </p>
      <p>
        2.4 A partida será disputada por duas equipes, cada uma composta por cinco
        atletas, onde um dos quais, obrigatoriamente, será o goleiro.
      </p>
      <p>
        2.5 Qualquer um dos atletas poderá trocar de posição com o goleiro, desde
        que tal alteração seja efetuada quando de uma paralisação da partida.
      </p>

      <H2>3 - Início da Partida</H2>
      <p>
        3.1- Para o início da partida a escolha do goleiro ou pontapé inicial será
        sorteado utilizando-se o jogo do par ou ímpar; o lado favorecido escolherá
        a saída de bola ou o goleiro.
      </p>

      <H2>4 - Substituição</H2>
      <p>
        4.1 O próximo da vez será o substituto na partida vigente, podendo jogar a
        próxima partida em sua totalidade somente em casos de contusão do atleta que
        será substituído. Em qualquer outro caso o próximo atleta pode ser alguém
        da fila de espera, que automaticamente perde a vez.
      </p>

      <H2>5 – Infrações e penalidades</H2>
      <p>
        5.1 Quando houver árbitro na partida o mesmo tem total autoridade,
        prevalecendo sua decisão em qualquer caso, estando limitado às decisões de
        campo.
      </p>
      <p>
        5.2 Quando não houver árbitro na partida a infração só pode ser marcada
        pelo atleta que está envolvido diretamente na jogada (disputando a bola) se
        outro atleta paralisar a partida receberá um cartão amarelo mesmo que não
        haja árbitro.
      </p>
      <p>
        5.3 Em caso de retorno da bola ao goleiro o mesmo só poderá pegar a bola
        com a mão se o retorno for realizado de cabeça, peito ou originado de
        lateral, caso não seja realizado de nenhuma das formas mencionadas deve ser
        marcada a infração e o time favorecido deverá escolher onde será cobrada a
        falta fora da área do goleiro.
      </p>
      <p>
        5.4 É proibida a utilização de carrinho para disputa de bola, visto o
        tamanho do nosso campo, o que pode causar contusões com alta gravidade,
        caso ocorra o atleta receberá um cartão amarelo automaticamente mesmo que
        não haja árbitro na partida.
      </p>
      <p>
        5.5 Em caso de aplicação de 2 cartões amarelos na partida ao mesmo atleta,
        o mesmo receberá um cartão azul e passará 2 minutos fora da partida ou até
        que seu time sofra um gol.
      </p>
      <p>
        5.6 Em caso do atleta atacante sofrer uma falta como por exemplo ( puxada de
        camisa, abraço, puxão no braço, carrinho por trás, faltas maliciosas) e
        possua apenas um atleta de defesa impossibilitando a marcação de um gol, o
        infrator receberá o cartão azul automaticamente.
      </p>
      <p>
        5.7 Caso o goleiro pegue com a mão a bola fora da área, o mesmo deve receber
        um cartão amarelo e a infração deve ser marcada no local onde a bola foi
        tocada com a mão. Obs: A referência é a bola e não o corpo do goleiro sendo
        assim, se a bola estiver fora da área e o corpo do goleiro dentro a infração
        deve ser marcada.
      </p>
      <p>
        5.8 Em caso do atleta cometer uma penalidade de modo a desvirtuar o principal
        objetivo da diversão ou ofender algum colega da pelada, o mesmo deve receber
        cartão vermelho e estará suspenso da partida em andamento, podendo retornar
        no mesmo dia.
      </p>
      <p>
        5.9 No caso da aplicação do cartão azul, o atleta poderá voltar
        imediatamente, caso qualquer um dos times marque um gol;
      </p>
      <p>
        5.10 A marcação branca para cobrança do pênalti deve ser utilizada apenas
        como referência, o local exato para cobrança será de dois passos a mais de
        distância em sentido oposto ao da posição do goleiro defensor.
      </p>

      <H2>6 – Taxa de manutenção</H2>
      <p>
        6.1 O valor de <b>R$ 50,00</b> reais por mês deve ser pago pelos atletas para que seja
        realizada a manutenção do material utilizado na pelada e pagamento de
        contratação dos goleiros e árbitros. Em dezembro o valor que restar no caixa
        será usado na confraternização da pelada com a devida prestação de contas.
        Em caso de 2 meses ou mais atraso no pagamento o atleta poderá ser convidado
        a se retirar da pelada e não poderá participar da festa de confraternização.
      </p>
      <p>
        6.2 Só poderá participar das deliberações no grupo da pelada e emitir sua
        opinião, aquele que se encontrar quite com sua colaboração mensal;
      </p>

      <H2>7 Vestimenta e material dos atletas.</H2>
      <p>
        7.1 É obrigatório o atleta entrar em campo com o padrão oficial da pelada.
        O convidado poderá utilizar colete amarelo ou colete azul, podendo usar
        também uma camisa de mesma cor (o atleta deve possuir 2 camisas para
        eventual troca).
      </p>

      <H2>8 – Convidados</H2>
      <p>
        8.1 Fica proibida a participação de convidados caso exista 3 times completos
        ou mais.
      </p>
      <p>
        8.2 O valor de R$ 15,00 reais por pelada será cobrado diretamente ao morador que
        trouxer um convidado, e deve ser pago de forma antecipada, ou seja, antes de
        iniciar a partida.
      </p>
    </div>
  );
}

export default Regras;
