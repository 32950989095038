import styled from "styled-components";
import Card from '@mui/material/Card';

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
background-color: #B0C4DE;
min-height: 100vh;
width: 100%;
padding: 20px;
background-image: url('/background_app.jpeg'); /* Caminho relativo a partir de public */
  background-size: cover;
`;

export const ContainerModal = styled.div`
display: flex;
flex-direction: row;
align-items: center;


`;
export const ContainerButuon = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 80%;

@media (max-width: 768px) {
  
    flex-direction: column;
  }


`;

export const Title = styled.h1`
margin: 20px;
background-color: #f0f0f0;
border-radius: 5%;
padding: 5px;
`;



export const Button = styled.button`
margin-top: 10px;
margin-left: 5px;
padding: 5px 5px;
background-color: #caed9d;
color: #ffffff;
border: solid;
border-radius: 5px;
cursor: pointer;
width: 80%;

&:hover {
  background-color: #0056b3;
}
`;

export const EnvioButton = styled(Button)`
padding: 20px;
font-weight: bold;
background-color:blueviolet;

`;

export const ZerarButton = styled(EnvioButton)`
background-color: #ce6f79;
`;

export const ContainerJogador = styled.div`
display: flex;
flex-direction: column;
/* background-color: red; */
width: 80%;
align-items: center;
`

export const JogadorCard = styled.div`
border: 1px solid #040000;
border-radius: 5px;
padding: 10px;
width: 100%; /* 3 jogadores por linha em telas maiores */
box-sizing: border-box;
cursor: pointer;
transition: background-color 0.3s ease;
background-color: rgb(76, 148, 242);
color: #ffffff;
font-weight: bold;
display: flex;
flex-direction:row;
flex-wrap: nowrap;
max-width: 100%;
align-items: center;



&:hover {
  background-color: #caed9d;
}
`;

export const AddButton = styled.div`
margin-left: 5px;
background-color: rgb(84, 241, 163);
color: #090ce3;
font-weight: bold;
border: 1px solid #fbfbfb;
border-radius: 5%;
padding: 2px;
align-items: center;
width: 150px;
box-sizing: border-box;
cursor: pointer;
transition: background-color 0.3s ease;
display: flex; /* Adicione isso para permitir alinhamento vertical */
justify-content: center; /* Adicione isso para alinhar horizontalmente */

img {
  max-width: 100%;
  max-height: 100%;
}
`;

export const StyledColoredCard = styled(Card)`
  width: 280px; /* Aumentado para melhor visibilidade */
  height: 250px; /* Aumentado para acomodar mais informações */
  background-image: url('image/camisa.png'); /* Imagem de fundo */
  background-size: cover; /* Faz a imagem cobrir todo o card */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Não repete a imagem */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Mudado para centralizar as informações */
  margin: 10px;
  position: relative;
  padding: 15px; /* Adicionado padding para espaçamento interno */
  transition: transform 0.2s; /* Efeito de transição suave */

  &:hover {
    transform: scale(1.05); /* Efeito de zoom ao passar o mouse */
  }
`;


export const ShirtNumber = styled.div`
  font-size: 3em;
  font-weight: bold;
  color: #333;
  margin-top: 10px; /* Espaço reduzido para melhor visualização */
`;

export const PlayerName = styled.div`
  font-size: 1.5em; /* Aumentado para melhor legibilidade */
  font-weight: bold;
  color: #333;
  margin-bottom: 20px; /* Adicionado espaço abaixo do nome */
`;

export const InfoText = styled.p`
  font-size: 0.9em; /* Tamanho de fonte ajustado */
  color:${(props) => props.pagou=='SIM'? '#666': 'red'};  /* Cor de texto mais suave */
  margin: 5px 0; /* Margem para espaçamento entre linhas */
  position: center;
`;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px; /* Espaço acima dos ícones */
`;