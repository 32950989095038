import React, { useState, useRef, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';

import Pot from './../../components/Pot'
import api from '../../api';
import Header from "../../components/Header";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import PieChart from './../../charts/pie'
import { DialogActions, DialogContent, DialogTitle, IconButton, Dialog } from '@mui/material';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DataGrid } from '@mui/x-data-grid';
import VotePlayer from "../../components/VotePlayer";
import ImageUploadModal from '../../components/UploadModal'
import Button from '@mui/material/Button';

import {

  DialogTitleStyled,
  DialogContentStyled,
  DialogActionsStyled,
} from './Styles';





const Aprove = () => {



  const { isAuthenticated, userLogado } = useAuth();

  const [modalOpen, setModalOpen] = useState(false);



  const [player, setPlayer] = useState('');
  const [qtdVotos, setQtdVotos] = useState([]);
  const [qtdVotosUserLogado, setQtdVotosUserLogado] = useState([]);
  const [qtdUserForTorre, setQtdUserForTorre] = useState([]);
  const [raking, setRanking] = useState(0);
  const [myPote, setMyPote] = useState('');
  const [diretorioImagePote, setDiretorioImagePote] = useState('');
  const [renderPieVotoUsers, setRenderPieVotoUsers] = useState(false);
  const [renderMyPote, setRenderMypote] = useState(false);
  const [potes, setPotes] = useState([]);
  const [listUserRaking, setListUserRaking] = useState([]);
  const [confirmDialogOrdemChegada, setConfirmDialogOrdemChegada] = useState(false);
  const [torneios, setTorneios] = useState([]);
  const [torneioSelecionado, setTorneioSelecionado] = useState(null);




  const [loading, setLoading] = useState(false);

  const [severity, setSeverity] = useState('success');

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleImageUpload = async (imageData) => {
    try {

      alert(imageData)
      // Crie um objeto FormData para enviar a imagem
      const formData = new FormData();
      formData.append("file", imageData);

      // Faça uma solicitação POST para a URL da API
      const response = await api.post("files", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Certifique-se de definir o tipo de conteúdo correto para FormData
        },
      });

      // Verifique se a solicitação foi bem-sucedida
      if (response.status === 200) {
        console.log("Imagem enviada com sucesso!");
        // Lide com qualquer outra ação que você deseja realizar após um upload bem-sucedido
      } else {
        console.error("Erro ao enviar a imagem para o servidor.");
        // Lide com erros de envio aqui, se necessário
      }
    } catch (error) {
      console.error("Erro ao fazer o upload da imagem:", error);
      // Lide com erros de upload de imagem aqui
    } finally {
      // Após o upload bem-sucedido ou com erro, você pode fechar o modal
      handleCloseModal();
    }
  };

  const handleCloseSuccess = () => {
    setOpenMessage(false);
  }

  const getQtdVotos = async () => {
    try {
      console.log('chamou votos');

      const response = await api.get('report/votos', { params: { torneioId: torneioSelecionado } });
      setQtdVotos(response.data)
    } catch (error) {
      console.error('Erro ao obter jogadores:', error);
      return [];
    }
};

  const getTorneios = async () => {
    try {


      const response = await api.get('getTorneios');
      setTorneios(response.data)
      if(torneioSelecionado==null)
      setTorneioSelecionado(response.data[1]._id)


    } catch (error) {
      console.error('Erro ao obter Torneios:', error);
      return [];
    }

  };

  const getQtdVotosUserLogado = async () => {
    try {
      const response = await api.get('report/qtdvotosforportsuser', { params: { torneioId: torneioSelecionado } });
      setQtdVotosUserLogado(response.data)
      setRenderPieVotoUsers(true);

    } catch (error) {
      console.error('Erro ao obter Votos User:', error);
      return [];
    }

  };

  const getMyPote = async () => {
    try {
      const request = { torneio_id: torneioSelecionado }
      const response = await api.post('user/ranking', request);

      if (response.status==200 && response.data) {
        setRanking(response.data.ranking.ranking);
        setMyPote(response.data.ranking.myPote)
        setRenderMypote(true)

      }else{
        setRenderMypote(false)

      }
    } catch (error) {
      setRenderMypote(false)
      setRanking(0);

      console.error('Erro ao obter my pote', error);
      return [];
    }

  };

  const getUserForTorre = async () => {
    try {
      const response = await api.get('report/getQtdForTorre');

      if (response.data) {
        setQtdUserForTorre(response.data)

      }
    } catch (error) {
      console.error('Erro obter user for torre', error);
      return [];
    }
  }
  const getUsersRakingTotal = async () => {
    try {
      const response = await api.get('report/getPotesforPeso', { params: { torneioId: torneioSelecionado } });

      if (response.data) {
        setListUserRaking(response.data)

      }
    } catch (error) {
      console.error('Erro obter lista de usuarios ranking', error);
      return [];
    }

  };

  const getPotes = async () => {
    try {
      const response = await api.post('/getPotesFinalTorneio',{torneio:torneioSelecionado});

      if (response.data) {
        setPotes(response.data)

      }
    } catch (error) {
      console.error('Erro obter user for torre', error);
      return [];
    }

  };


  const getPeladaActive = async () => {
    try {
      const response = await api.get('/getPeladaActive');

      if (response.data.data) {
        setConfirmDialogOrdemChegada(true);

      }
    } catch (error) {
      console.error('Erro obter user for torre', error);
      return '';
    }

  };




  useEffect(() => {




    if (isAuthenticated) {
      getTorneios();
      getPeladaActive()
      getQtdVotos();
      getUserForTorre();
      getQtdVotosUserLogado();
      getMyPote();
      getPotes();
      getUsersRakingTotal();
    }





  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getTorneios();
      getQtdVotos();
      getUserForTorre();
      getQtdVotosUserLogado();
      getMyPote();
      getPotes();
      getUsersRakingTotal();
    }
  }, [torneioSelecionado])

  // Mapeamento entre valores de myPote e nomes de imagens correspondentes
  const poteImageMap = {
    pote1: "/number1.png",
    pote2: "/number2.png",
    pote3: "/number3.png",
    pote4: "/number4.png",
    pote5: "/number5.png",
    pote6: "/number6.png",
    // Adicione mais mapeamentos conforme necessário
  };

  useEffect(() => {
    // Verifique se myPote é um valor válido no mapeamento
    if (myPote in poteImageMap) {
      setDiretorioImagePote(poteImageMap[myPote]);
    } else {
      // Defina um valor padrão ou lide com a falta de correspondência
    }
  }, [myPote]);



  const handleChange = (event) => {
    setTorneioSelecionado(event.target.value);
  };



  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  const dataPie = {
    labels: ['Votos Realizados', 'Não realizados'],
    datasets: [
      {
        label: 'Número de votos',
        data: qtdVotos,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',

        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',

        ],
        borderWidth: 1,
      },
    ],
  };

  const dataPieTorre = {
    labels: ['Natura', 'Acqua'],
    datasets: [
      {
        label: 'Usuários',
        data: qtdUserForTorre,
        backgroundColor: [
          'green',
          'blue',

        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',

        ],
        borderWidth: 1,
      },
    ],
  };

  const dataPieVotosUserLogado = {
    labels: ['Pote 1', 'pote 2', 'pote 3', 'pote 4', 'pote 5', 'pote 6'],
    datasets: [
      {
        label: 'Votos',
        data: qtdVotosUserLogado,
        backgroundColor: [
          'rgb(127, 255, 0)',
          'rgb(255, 255, 0)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgb(128, 0, 128)',
          'rgb(255, 165, 0)',

        ],
        borderColor: [
          'rgb(127, 255, 0)',
          'rgb(255, 255, 0)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgb(128, 0, 128)',
          'rgb(255, 165, 0)',

        ],
        borderWidth: 1,
      },
    ],
  };


  const columns = [
    // { field: 'id', headerName: 'id', width: 130 },
    { field: 'jogador', headerName: 'Jogador', width: 200 },
    { field: 'valorTotal', headerName: 'Pontos', width: 300 },
  ];


  return (


    <>
      <Header />
      <div>
        <label htmlFor="torneio-select">Selecione um Torneio:</label>
        <select
          id="torneio-select"
          value={torneioSelecionado}
          onChange={handleChange}
        >
          <option value="">Selecione...</option>
          {torneios.map((torneio) => (
            <option key={torneio._id} value={torneio._id}>
              {torneio.nome}
            </option>
          ))}
        </select>
       
      </div>
      <Snackbar open={openMessage} onClose={handleCloseSuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
        <MuiAlert elevation={6} variant="filled" severity={severity} sx={{ width: '100%' }}>
          {message}
        </MuiAlert>
      </Snackbar>
      <Dialog open={confirmDialogOrdemChegada} onClose={() => setConfirmDialogOrdemChegada(false)}>
        <DialogTitleStyled>Existe Pelada Aberta. Deseja ir para ordem de chegada?</DialogTitleStyled>

        <DialogActionsStyled>
          <Button onClick={() => setConfirmDialogOrdemChegada(false)} color="secondary">
            Não
          </Button>
          <Button onClick={() => navigate('/ordem-chegada')} color="primary">
            Sim
          </Button>
        </DialogActionsStyled>
      </Dialog>

      <CardContent>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3, sm: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>

            <Grid xs={2} sm={4} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  title="Total de votos"
                />
                <PieChart data={dataPie}></PieChart>
              </Card>
            </Grid>
            <Grid xs={2} sm={4} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  title="Usuários por Torre"
                />
                <PieChart data={dataPieTorre}></PieChart>
              </Card>
            </Grid>
            <Grid xs={2} sm={4} md={4}>
              {renderPieVotoUsers ? (
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    title="Votos ao meu favor"
                  />
                  <PieChart data={dataPieVotosUserLogado}></PieChart>
                </Card>
              ) : (<Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  title="Realize o voto para visualizar seus votos recebidos"
                />

              </Card>)}
            </Grid>
            <Grid xs={2} sm={4} md={4}>
              {renderMyPote ? (

                <Card sx={{ maxWidth: 350 }}>
                  <CardHeader title="Meu Pote atual" />
                  <CardMedia
                    sx={{ width: "100%", height: "auto", maxWidth: "100%" }}
                    component="img"
                    height="350"
                    image={diretorioImagePote}
                    alt="Pote"
                  />
                </Card>
              ) : <Card sx={{ maxWidth: 350 }}>
                <CardHeader title="Realize seu voto para visualizar seu pote atual aqui." />

              </Card>}
            </Grid>
            <Grid xs={2} sm={4} md={4}>
              {renderMyPote ? (

                <Card sx={{ maxWidth: 350 }}>
                  <CardHeader title="Meu Ranking" />
                  <CardHeader title={raking} />

                </Card>
              ) : <Card sx={{ maxWidth: 350 }}>
                <CardHeader title="Realize seu voto para visualizar seu pote atual aqui." />

              </Card>}
            </Grid>
          </Grid>
          <Typography variant="h4" gutterBottom>
            Potes Formados
          </Typography>

          {potes ? (
            <Grid container spacing={2}>
              <Divider light />
              {Object.keys(potes).map((potNum) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={potNum}>

                  <div >
                    <CardContent>
                      <Typography>
                        <Pot
                          key={potNum}
                          potNum={potNum}
                          playersInPot={potes[potNum]}
                        // onDrop={(e) => handleDrop(e, potNum)}
                        // onPlayerRemove={handlePlayerRemove}

                        />
                      </Typography>
                    </CardContent>
                  </div>
                </Grid>
              ))}

            </Grid>

          ) : (<></>)}




        </Box>

        {listUserRaking.length > 0 ? (
          <>
            <Divider light />
            <Typography variant="h4" gutterBottom>
              Ranking Jogadores
            </Typography>
            <div style={{ height: 430, width: '100%' }}>
              <DataGrid
                rows={listUserRaking}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 6 },
                  },
                }}
                pageSizeOptions={[6, 36]}
              // checkboxSelection
              />
            </div>
          </>
        )
          : (<></>)
        }

        <VotePlayer torneioId = {torneioSelecionado} />

      </CardContent>




    </>
  );
};

export default Aprove;


