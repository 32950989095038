import React from 'react';
import styled from 'styled-components';
import Player from './Player';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const WaitListContainer = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const WaitListTitle = styled.h2`
  text-align: center;
  margin-bottom: 15px;
  color: blueviolet;
`;

const WaitListPlayerContainer = styled.div`
  margin: 10px 0;
  padding: 10px;
  background: ${props => props.isFirstFour ? '#d4edda' : '#f1f1f1'};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpArrow = styled(IconButton)`
color: ${props => props.disabled ? '#d1d1d1' : 'green'};
`;

const DownArrow = styled(IconButton)`
color: ${props => props.disabled ? '#d1d1d1' : 'red'};
`;

const WaitList = ({ waitList, onRemovePlayer, onUpdatePlayerPosition, title }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedPlayer, setSelectedPlayer] = React.useState(null);

  const handleRemoveClick = (player) => {
    setSelectedPlayer(player);
    setOpenDialog(true);
  };

  const handleConfirmRemove = () => {
    if (selectedPlayer) {
      onRemovePlayer(selectedPlayer.user); // Passa o user ID para a função de remoção
    }
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPlayer(null);
  };

  const handleMovePlayer = (player, direction) => {
    onUpdatePlayerPosition(direction,player.user_name); // Passa o user ID e direção para a função de atualização
  };

  return (
    <WaitListContainer>
      <WaitListTitle>{title}</WaitListTitle>
      {waitList.map((player, index) => (
        <WaitListPlayerContainer key={player.user} isFirstFour={index < 4}>
          <Player player={player} />
          <ArrowContainer>
            <UpArrow
              disabled={index === 0}
              onClick={() => handleMovePlayer(player, 'up')}
            >
              <ArrowUpwardIcon />
            </UpArrow>
            <DownArrow
              disabled={index === waitList.length - 1}
              onClick={() => handleMovePlayer(player, 'down')}
            >
              <ArrowDownwardIcon />
            </DownArrow>
          </ArrowContainer>
          <Button variant="contained" color="secondary" onClick={() => handleRemoveClick(player)}>
          <DeleteForeverRoundedIcon></DeleteForeverRoundedIcon>
          </Button>
        </WaitListPlayerContainer>
      ))}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmar Remoção</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
          <Button onClick={handleConfirmRemove} color="secondary">Confirmar</Button>
        </DialogActions>
      </Dialog>
    </WaitListContainer>
  );
};

export default WaitList;
