import React, { createContext, useContext, useState, useEffect } from 'react';
import api from './api'
const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated')) || false
  );

  const [userLogado, setUserLogado] = useState(  JSON.parse(localStorage.getItem('user')) || {nome:''});
  const [token, setToken] = useState(  JSON.parse(localStorage.getItem('token')) || '');
  

 

  const login = async (user, password) => {
    // Lógica de autenticação aqui
    // Quando o login for bem-sucedido, chame setIsAuthenticated(true)

    try {

        const body ={
            email: user,
            password: password
        }
       
        const response = await api.post('sessions', body);
        setIsAuthenticated(response.data.auth);
        setUserLogado(response.data.user);
        setToken(response.data.token)

        // api.defaults.headers.Authorization = `Bearer ${token}`;
       
        
        return({IsAuthenticated:isAuthenticated, message:''})
      } catch (error) {
        console.error('Erro ao logar', error);
       
        return({IsAuthenticated:false, message:error.response.data.erro})
      }
    
  };

  const logout = () => {
    // Lógica para fazer logout
    setIsAuthenticated(false);
    // console.log("setou false");
  };

  useEffect(() => {
    if (isAuthenticated) {
        console.log("auteticado")
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 2); // Expire após dois dias

      const authData = {
        expirationDate: expirationDate.toISOString(),
      };

      localStorage.setItem('authData', JSON.stringify(authData));
      localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('user', JSON.stringify(userLogado));
      // api.api.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
        console.log("removendo login")
      localStorage.removeItem('authData');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    //   setUserLogado({});
    }
  }, [isAuthenticated, userLogado,token]);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('authData'));
    if (authData && new Date(authData.expirationDate) < new Date()) {
        console.log(authData);
        setIsAuthenticated(false)
        setUserLogado();
        setToken()
      } 
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userLogado,token }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
