import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import api from "../../api";
import Header from "../../components/Header";

const RankingPage = () => {
  const [rankingData, setRankingData] = useState([]);
  const [minJogos, setMinJogos] = useState(0);
  const [partidas, setPartidas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [partidasPage, setPartidasPage] = useState(0);
  const [totalPartidas, setTotalPartidas] = useState(0);
  const [selectedDate, setSelectedDate] = useState(""); // State for the selected date
  const [openExplanation, setOpenExplanation] = useState(false);
  const partidasPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rankingResponse = await api.get("/report/aproveitamento");
        const partidasResponse = await api.get(
          `/report/patidas?page=${partidasPage}&limit=${partidasPerPage}&date=${selectedDate}`
        );
        setRankingData(rankingResponse.data.ranking);
        setMinJogos(rankingResponse.data.minJogos);
        setPartidas(partidasResponse.data.partidas);
        setTotalPartidas(partidasResponse.data.total);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [partidasPage, selectedDate]);

  const handleNextPage = () => {
    if ((partidasPage + 1) * partidasPerPage < totalPartidas) {
      setPartidasPage(partidasPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (partidasPage > 0) {
      setPartidasPage(partidasPage - 1);
    }
  };

  const handleOpenExplanation = () => {
    setOpenExplanation(true);
  };

  const handleCloseExplanation = () => {
    setOpenExplanation(false);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); // Update selectedDate with the chosen date
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Header />
      <Typography variant="h4" gutterBottom>
        Ranking de Jogadores
      </Typography>
      <>Mínimo de jogos para não sofrer penalidade: {minJogos}</>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpenExplanation}
      >
        Explicação da Fórmula
      </Button>
      <Dialog
        open={openExplanation}
        onClose={handleCloseExplanation}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Explicação da Fórmula de Eficiência
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Objetivo da Fórmula:</strong><br />
            A ideia por trás dessa fórmula
            é garantir que jogadores com menos jogos não fiquem à frente de
            jogadores que participaram de mais partidas apenas por terem um
            desempenho positivo em poucas partidas. Isso é feito adicionando uma
            penalidade para quem jogou menos que o minimo de jogos. (Quantidade
            de peladas * 3.) <br />
          </DialogContentText>
          <DialogContentText>
            A fórmula usada para calcular a eficiência dos jogadores leva em
            consideração o número de vitórias, empates, derrotas e uma
            penalidade para quem jogou menos do que o número mínimo de jogos
            (número de peladas * 3).
          </DialogContentText>
          <DialogContentText>
            <strong>Fórmula:</strong> <br />
            <code>
              Pontuação = (0.5 * Vitórias) + (0.25 * Empates)
              <br />
              Penalidade = (Número mínimo de jogos - Número de Jogos) * 0.1{" "}
              <br />
              Eficiência = [(Pontuação / Número de Jogos) - Penalidade] * 100
            </code>
          </DialogContentText>
          <DialogContentText>
            <strong>Exemplo 1 (sem penalidade):</strong> <br />
            Suponha que um jogador tenha jogado **9 partidas**, com 5 vitórias,
            2 empates e 2 derrotas. <br />
            A pontuação será: <br />
            <code>Pontuação = (0.5 * 5) + (0.25 * 2) = 2.5 + 0.5 = 3.0</code>
            <br />
            Como ele jogou o número mínimo de jogos (9 jogos), **não há
            penalidade**. <br />
            <strong>Eficiência:</strong>
            <br />
            <code>Eficiência = [(3.0 / 9) - 0] * 100 = 33.33%</code>
          </DialogContentText>
          <DialogContentText>
            <strong>Exemplo 2 (com penalidade):</strong> <br />
            Agora, suponha que um jogador tenha jogado **5 partidas**, com 3
            vitórias, 1 empate e 1 derrota. <br />
            A pontuação será: <br />
            <code>Pontuação = (0.5 * 3) + (0.25 * 1) = 1.5 + 0.25 = 1.75</code>
            <br />
            Como ele jogou menos que o número mínimo de jogos (9 jogos), **uma
            penalidade é aplicada**. <br />
            <strong>Penalidade:</strong> <br />
            <code>Penalidade = (9 - 5) * 0.1 = 0.4</code>
            <br />
            <strong>Eficiência:</strong> <br />
            <code>Eficiência = [(1.75 / 5) - 0.4] * 100 = 25.00%</code>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseExplanation} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

     

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Posição</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Eficiência</TableCell>
              <TableCell>Jogos</TableCell>
              <TableCell>Penalidade</TableCell>
              <TableCell>Pontos</TableCell>
              <TableCell>Vitórias</TableCell>
              <TableCell>Empates</TableCell>
              <TableCell>Derrotas</TableCell>
              <TableCell>Aproveitamento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rankingData.map((jogador, index) => (
              <TableRow key={jogador.nome}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{jogador.nome}</TableCell>
                <TableCell>{jogador.eficiencia}</TableCell>
                <TableCell>{jogador.jogos}</TableCell>
                <TableCell>{jogador.penalidade}</TableCell>
                <TableCell>{jogador.pontuacao}</TableCell>
                <TableCell>{jogador.vitorias}</TableCell>
                <TableCell>{jogador.empates}</TableCell>
                <TableCell>{jogador.derrotas}</TableCell>
                <TableCell>{jogador.aproveitamento}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5" gutterBottom>
        Histórico de Partidas
      </Typography>
       {/* Date Filter */}
       <TextField
        id="date"
        label="Filtrar por data da pelada"
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Time 1</TableCell>
              <TableCell>Time 2</TableCell>
              <TableCell>Resultado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partidas.map((partida) => (
              <TableRow key={partida._id}>
                <TableCell>
                  {new Date(partida.dataPartida).toLocaleDateString()}
                </TableCell>
                <TableCell style={{ backgroundColor: "lightblue" }}>
                  {partida.times[0]
                    .map((jogador) => jogador.user_name)
                    .join(", ")}
                </TableCell>
                <TableCell style={{ backgroundColor: "lightyellow" }}>
                  {partida.times[1]
                    .map((jogador) => jogador.user_name)
                    .join(", ")}
                </TableCell>
                <TableCell>
                  {partida.vencedor === -1
                    ? "Empate"
                    : `Time ${partida.vencedor === 0 ? "1" : "2"} Vencedor`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ marginTop: "16px" }}>
        <Button onClick={handlePreviousPage} disabled={partidasPage === 0}>
          Anterior
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={(partidasPage + 1) * partidasPerPage >= totalPartidas}
        >
          Próxima
        </Button>
      </div>
    </div>
  );
};

export default RankingPage;

