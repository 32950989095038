import React, { useState } from "react";
import styled from "styled-components";
import Team from "./Team";
import WaitList from "./WaitList";

const SimulationtTitle = styled.h2`
  text-align: center;
  margin-bottom: 15px;
  color: blueviolet;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  background-color: #ff5a5f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  float: right;

  &:hover {
    background-color: #ff3b3b;
  }
`;

const TeamsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TeamCard = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 45%;
`;

const Modal = ({ isOpen, onClose, teams, waitList }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>X</CloseButton>
          <SimulationtTitle>Simulação</SimulationtTitle>
        <TeamsContainer>
          <TeamCard bgColor="#007bff" textColor="#ffffff">
            <h2>Time 1</h2>
            <Team team={teams[0]} />
          </TeamCard>
          <TeamCard bgColor="#ffc107" textColor="#000000">
            <h2>Time 2</h2>
            <Team team={teams[1]} />
          </TeamCard>
        </TeamsContainer>
        <WaitList title="Fila de Espera" waitList={waitList} />
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
