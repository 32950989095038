import './PlayCard.css'; // Importe o arquivo CSS para estilização

const PlayerCard = ({ playerName, tower, apartment, playerImage, ranking, pote, timeImage, paisImage }) => {
  return (
    <div className="player-card custom-card">
      <div className="player-image">
        <img src={playerImage}  className="player-image"  />
      </div>
      <div className="player-time-image">
        <img src={timeImage}  className="player-time-image"  />
      </div>
      {paisImage!='/bandeira_BR.png'?(
        <div className="player-pais-image">
        <img src={paisImage}  className="player-pais-image"  />
      </div>
      ):<div></div>}
      
      <div className="player-info">
        <h3>{playerName}</h3>
        <p>Torre: {tower}</p>
        {/* <p>Apt: {apartment}</p> */}
        <h4>Ranking: {ranking}</h4>
        <h4>pote: {pote}</h4>
      </div>
    </div>
  );
};

export default PlayerCard;
