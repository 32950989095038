import styled from 'styled-components';

export const ConfrontoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Escudo = styled.img`
  width: 300px;
  height: 300px;
  margin-right: 20px;
`;

export const ConfrontoInfo = styled.div`
  font-size: 16px;
`;

export const Horario = styled.h2`
  font-weight: bold;
  color: red;
`;

export const Placar = styled.p`
  font-weight: bold;
`;
