
import React, { useState, useEffect } from 'react';
import api from '../api';
import {Title,Button as ButtonPerson, ZerarButton,  Container,EnvioButton, JogadorCard,AddButton,ContainerModal, ContainerJogador, ContainerButuon, StyledColoredCard, ShirtNumber, PlayerName } from './pots-styles'
import Jogador from '../components/Jogador';
import Pot from '../components/Pot'
import Modal from '../components/Modal';
import AddImage from '../images/add.png';
import { useAuth } from '../AuthContext'
import { Navigate , useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Header from '../components/Header';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Button   from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { color } from 'chart.js/helpers';




const Pots = () => {
    
const [mensageSuccess, setMensageSuccess] = useState('');
const [success, setSuccess] = useState(false);
const [mensageError, setMensageError] = useState('');
const [error, setError] = useState(false);
const [load, setLoad] = useState(false); 
 
  const [eleitor, setEleitor] = useState([]);

  const [jogadores, setJogadores] = useState([]);
  const [jogadoresCompleto, setJogadoresCompleto] = useState([]);
  // Dentro do componente App
const [codigoVotacao, setCodigoVotacao] = useState(''); 
const [votoBranco, setVotoBranco] = useState(false); 

const [potes, setPotes] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  });

const [poteAtual, setPoteAtual] = useState(6);


const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedPlayer, setSelectedPlayer] = useState(null);
const [selectedPot, setSelectedPot] = useState(poteAtual);
const [open, setOpen] = useState(false);
const [alert, setAlert] = useState(true);

const { isAuthenticated } = useAuth();
const navigate = useNavigate();

const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleResetPotes = () => {
    // Zerar os potes
    const newPotes = {};
    for (let i = 1; i <= 6; i++) {
      newPotes[i] = [];
    }
    
    setPotes(newPotes);
  
    // Colocar todos os jogadores disponíveis novamente
    fetchPlayers(); 
  
    // Resetar o pote atual
    setPoteAtual(6);
  };
  
  const handleCloseError = () => {
    setError(false);
  };

  const showSuccess = async(msg)=>{
      setSuccess(true);
    setMensageSuccess(msg)
  }

  const handleCloseSuccess = () => {
    setSuccess(false);
  };

  const showError = async(msg)=>{
      setError(true);
    setMensageError(msg)
  }


 // Defina a função assíncrona getPlayers
 const getPlayers = async () => {
    try {
      const response = await api.get('get-players');
      const nomesJogadores = response.data.map(jogador => jogador.nome);
      setJogadoresCompleto(response.data)
      return nomesJogadores;
    } catch (error) {
      console.error('Erro ao obter jogadores:', error);
      return [];
    }
  };

  async function fetchPlayers() {
    const nomesJogadores = await getPlayers();
    setEleitor(nomesJogadores);
    setJogadores(nomesJogadores);
  }

  // Use o hook useEffect para buscar os jogadores ao montar o componente
  useEffect(() => {
   

    fetchPlayers();
  }, []);

  

  const handleDragStart = (e, jogador) => {
    e.dataTransfer.setData('text/plain', jogador);
  };

// Função para calcular o percentual de inserção de cada jogador
const calculatePercentages = (potes, jogadores) => {
  const jogadorOccurrences = {};
  for (const jogador of jogadores) {
    jogadorOccurrences[jogador] = 0;
  }

  for (const poteNum in potes) {
    for (const jogador of potes[poteNum]) {
      jogadorOccurrences[jogador]++;
    }
  }

  const totalPotes = Object.keys(potes).length;
  const jogadorPercentages = {};
  for (const jogador in jogadorOccurrences) {
    const occurrence = jogadorOccurrences[jogador];
    jogadorPercentages[jogador] = occurrence / totalPotes;
  }

  return jogadorPercentages;
};

// Função para reorganizar os jogadores nos potes com base nos percentuais
const rearrangePlayers = (potes, jogadores) => {
  const jogadorPercentages = calculatePercentages(potes, jogadores);

  for (const poteNum in potes) {
    potes[poteNum].sort((a, b) => jogadorPercentages[b] - jogadorPercentages[a]);

    while (potes[poteNum].length > 6) {
      const jogadorToRemove = potes[poteNum].pop();
      const poteNumToMove = parseInt(poteNum) - 1;

      if (poteNumToMove > 0) {
        potes[poteNumToMove].push(jogadorToRemove);
      }
    }
  }
};

// ...

// Função para lidar com o drop e reorganizar os jogadores
const handlePlayerRemove = (e, potNum, jogador) => {
  e.preventDefault();
  const newPotes = { ...potes };
  newPotes[potNum] = newPotes[potNum].filter(item => item !== jogador);

  // Adicionar o jogador de volta à lista de jogadores disponíveis apenas se ele não estiver mais em nenhum pote
  const jogadorInAnyPot = Object.values(newPotes).some(pote => pote.includes(jogador));
  const newJogadores = jogadorInAnyPot ? jogadores : [...jogadores, jogador];
  setJogadores(newJogadores);

  rearrangePlayers(newPotes, newJogadores);
  setPotes(newPotes);
};


const handleDrop = (e, potNum) => {
  e.preventDefault();

  // Verificar se o pote atual corresponde ao pote em que o jogador está sendo adicionado
  if (potNum<poteAtual) {
      showError(`Você só pode adicionar jogadores ao pote inferior após completar o pote ${poteAtual} com 6 jogadores.`);
    return;
  }

  const jogador = e.dataTransfer.getData('text/plain');
  const newPotes = { ...potes };

  // Verificar se o pote atual já possui 6 jogadores
  if (newPotes[potNum].length >= 6) {
    showError('O pote  já possui 6 jogadores.');
    return;
  }

  newPotes[potNum] = [...newPotes[potNum], jogador];

  // Remover o jogador da lista de jogadores disponíveis
  const newJogadores = jogadores.filter(item => item !== jogador);
  setJogadores(newJogadores);

  rearrangePlayers(newPotes, newJogadores);
  setPotes(newPotes);

  // Verificar se o pote atual está cheio
  if (newPotes[potNum].length >= 6) {
    // Encontrar o próximo pote inferior
    let nextPote = parseInt(potNum) - 1;
    while (nextPote >= 1 && newPotes[nextPote].length >= 6) {
      nextPote--;
    }
    setPoteAtual(nextPote);
  }
};

const createPotFromPlayerArray = (playersArray) => {
  const potObject = {
    jogador1: playersArray[0] || '',
    jogador2: playersArray[1] || '',
    jogador3: playersArray[2] || '',
    jogador4: playersArray[3] || '',
    jogador5: playersArray[4] || '',
    jogador6: playersArray[5] || '',
  };
  return potObject;
};

let potsFinal ={};
const preparaPots = async()=>{
  console.log(potes[6])
 
  potsFinal={
    1:createPotFromPlayerArray(potes[1]),
    2:createPotFromPlayerArray(potes[2]),
    3:createPotFromPlayerArray(potes[3]),
    4:createPotFromPlayerArray(potes[4]),
    5:createPotFromPlayerArray(potes[5]),
    6:createPotFromPlayerArray(potes[6])
  }
 
}

async function getPublicIP() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error(error);
    return null;
  }
}



const handleEnviarPotes = async () => {
   
  const ip = await getPublicIP()

  let playerWithPotesData;
  if(votoBranco){
     playerWithPotesData = {
        ip:ip,
        votoBranco:votoBranco
      };

  }else{

      await preparaPots(potes);
      playerWithPotesData = {
       ip:ip,
       potes: potsFinal,
       votoBranco:votoBranco
     };
  }

  
console.log(playerWithPotesData);
  try {
    setLoad(true);
    playerWithPotesData.torneio_id= ''
    const response = await api.post('save-votos', playerWithPotesData);
    console.log('Resposta da API:', response.data);
    showSuccess('Lista de potes final enviada com sucesso!');
    handleResetPotes();
  } catch (error) {
    console.error('Erro ao enviar a lista de potes final:', error);
    showError('Erro ao enviar a lista de potes final: '+ error.response.data.message);
    if(error.response.data.voto){
        preenchePotes(error.response.data.voto)
    }
  }finally{
    setLoad(false)
  }
};


const preenchePotes = async(voto)=>{
    
    const newPotes = {};
    for (let i = 1; i <= 6; i++) {
      newPotes[i] = [];
    }
// Itere sobre as chaves do objeto 'potes.voto.potes'
for (const potNum in voto.potes) {
  // Verifique se o número do pote existe nos potes definidos anteriormente
  if (newPotes[potNum]) {
    // Extraia os jogadores do objeto 'potes.voto.potes[potNum]'
    const jogadores = Object.values(voto.potes[potNum]);
     // Exclua o jogador da posição 6, se existir
     if (jogadores.length >= 6) {
        jogadores.splice(6, 1);
      }
    console.log(jogadores);
    // Adicione os jogadores ao pote correspondente no novo objeto 'newPotes'
    newPotes[potNum] = [...newPotes[potNum], ...jogadores];
  }
}

// Atualize o estado dos potes com o novo objeto
setPotes(newPotes);
setJogadores([])
}



const openModal = (jogador) => {
  setSelectedPlayer(jogador);
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
  setSelectedPlayer(null);
  setSelectedPot(poteAtual);
};

const handlePotSelect = (potNum) => {
  setSelectedPot(potNum);
};

const handleAddToPot = () => {




  if (selectedPot !== '') {
    // Adicione aqui a lógica para adicionar o jogador ao pote selecionado
    // Pode usar a mesma lógica que você usou no drag and drop

// Verificar se o pote atual corresponde ao pote em que o jogador está sendo adicionado
if (selectedPot<poteAtual) {
    showError(`Você só pode adicionar jogadores ao pote inferior após completar o pote ${poteAtual} com 6 jogadores.`);
return;
}

const jogador = selectedPlayer;
const newPotes = { ...potes };

// Verificar se o pote atual já possui 6 jogadores
if (newPotes[selectedPot].length >= 6) {
    showError('O pote atual '+selectedPot+' já possui 6 jogadores.');
return;
}

newPotes[selectedPot] = [...newPotes[selectedPot], jogador];

// Remover o jogador da lista de jogadores disponíveis
const newJogadores = jogadores.filter(item => item !== jogador);
setJogadores(newJogadores);

rearrangePlayers(newPotes, newJogadores);
setPotes(newPotes);

// Verificar se o pote atual está cheio
if (newPotes[selectedPot].length >= 6) {
// Encontrar o próximo pote inferior
let nextPote = parseInt(selectedPot) - 1;
while (nextPote >= 1 && newPotes[nextPote].length >= 6) {
  nextPote--;
}
setPoteAtual(nextPote);
}

    closeModal(); // Feche o modal após adicionar o jogador
  }
};

const handleVotoBrancoSim= ()=>{
    setVotoBranco(true);
    setOpen(false);
}
const handleVotoBrancoNao= ()=>{
    setVotoBranco(false);
    setOpen(false);
}
const getFirstTwoWords = (str) => {
  return str.split(' ').slice(0, 2).join(' ');
};


const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 100%;
  
  
`;

const LoadingIndicator = styled.div`
  font-size: 40px;
  color: blueviolet;
`;



  return (
   <>
         <Snackbar open={error} onClose={handleCloseError} autoHideDuration={6000} anchorOrigin={{ vertical:'top', horizontal:'right' }} >
        <MuiAlert elevation={6}  variant="filled" severity="info" sx={{ width: '100%' }}>
          {mensageError}
        </MuiAlert>
      </Snackbar>
      <Snackbar open={error} onClose={handleCloseError} autoHideDuration={6000} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} >
        <MuiAlert elevation={6}  variant="filled" severity="info" sx={{ width: '100%' }}>
          {mensageError}
        </MuiAlert>
      </Snackbar>
      <Snackbar open={success} onClose={handleCloseSuccess} autoHideDuration={6000} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} >
        <MuiAlert elevation={6}  variant="filled" severity="success" sx={{ width: '100%' }}>
          {mensageSuccess}
        </MuiAlert>
      </Snackbar>

<Header></Header>
   <Container>
<Title>Monte seus potes</Title>
<Title> {jogadores.length} Jogadores inscritos </Title>

{/* <ContainerJogador> */}
<Grid container spacing={2}>
  {jogadores.slice().sort().map((jogador, index) => (
  
    <Grid item xs={12} sm={6} md={4} lg={2} key={jogador}>
 <Tooltip title="Clique ou Arraste para o pote desejado">
    <StyledColoredCard  onClick={() => openModal(jogador)}>
    <PlayerName>{getFirstTwoWords(jogador)}</PlayerName>
    <PlayerName>Camisa </PlayerName>
    <ShirtNumber> {jogadoresCompleto.find(k => k.nome === jogador)?.numeroCamisa}</ShirtNumber>
      <CardContent>
        <Typography>
          <Jogador
            key={index}
            jogador={jogador}
            onDragStart={handleDragStart}
          />
        </Typography>
      </CardContent>
    </StyledColoredCard>
  </Tooltip>
  </Grid> 
  ))}
  </Grid>
  {/* </ContainerJogador> */}

<Grid container spacing={2}>
  <Divider light />
{Object.keys(potes).map((potNum) => (
  
    <Grid item xs={12} sm={6} md={4} lg={2} key={potNum}>
    <div >
      <CardContent>
        <Typography>
        <Pot
             key={potNum}
             potNum={potNum}
             playersInPot={potes[potNum]}
             onDrop={(e) => handleDrop(e, potNum)}
             onPlayerRemove={handlePlayerRemove}
             showRemove={true}
 
        />
        </Typography>
      </CardContent>
    </div>
  </Grid> 
  ))}
  </Grid>

  <Divider></Divider>
  <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <p style={{color:'#ffff'}}>Marque para votar em branco.</p>
      <Checkbox style={{color:'#ffff'}}
      checked={votoBranco}
      onChange={handleClickOpen}
      inputProps={{ 'aria-label': 'controlled' }}
      label= 'Marque para votar em branco'
    />
     </Box>
<ContainerButuon>
<EnvioButton onClick={handleEnviarPotes}>Enviar Meus Potes</EnvioButton>
<ZerarButton onClick={handleResetPotes}>Limpar potes</ZerarButton>


</ContainerButuon>

<Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirmação"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que deseja votar em branco? Seu voto é importante!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVotoBrancoSim}>Sim</Button>
          <Button onClick={handleVotoBrancoNao}>Não</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={alert}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAlert}
        aria-describedby="alert-dialog-slide-description"
       
      >
        <DialogTitle  style={{backgroundColor:'green', color:'write'}}>{"Explicação"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Para votação você deve colocar nos potes menores os melhores jogadores.
          
           <p>Quanto menor o pote melhor você considera o jogador.</p>
            Ex: Pote 1 são sos melhores jogadores logicamente 2 ao 6 vai caindo a qualidade.
            <p> Você deve iniciar os votos pelo pote 6.</p>
          </DialogContentText>
        </DialogContent>
       
      </Dialog>



{/* Modal para adicionar jogador ao pote */}
{isModalOpen && (
<Modal onClose={closeModal}>
      <h2>Adicionar jogador ao pote</h2>
      <p>Selecione o pote onde deseja adicionar o jogador: {selectedPlayer}</p>
        <ContainerModal>
      <select value={selectedPot} onChange={(e) => handlePotSelect(e.target.value)}>
        <option value="">Selecione um pote</option>
        {Object.keys(potes).map((potNum) => (
          <option key={potNum} value={potNum}>Pote {potNum}</option>
          ))}
      </select>
      <AddButton onClick={handleAddToPot}>Adicionar</AddButton>
          </ContainerModal>
    </Modal>
  )}
 {load && (
      <LoadingOverlay>
        <LoadingIndicator>Enviando...</LoadingIndicator>
      </LoadingOverlay>
    )}
 </Container>
   </>

    
 

    
  );
};

export default Pots;
