import React from 'react';
import styled from 'styled-components';
import Player from './Player';

const TeamContainer = styled.div`
  margin: 20px 0;
`;

const TeamTitle = styled.h2`
  text-align: center;
  margin-bottom: 15px;
`;

const Team = ({ team }) => (
  <TeamContainer>
    {team.map(player => (
      <Player key={player.user} player={player} />
    ))}
  </TeamContainer>
);

export default Team;