import React, { useState, useRef, useEffect } from "react";
import api from '../api';

import Pot from './Pot'
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';







const VotePlayer = (torneioId) => {

  const [jogadorSelectd, setJogadorSelect] = useState('');
  const [players, setPlayers] = useState([]);
  const [potes, setPotes] = useState([]);
  const [renderPotes, setRenderPotes] = useState(false);
  


  const getPotesPlayer = async(jogador) => {
    try {
      // const body ={
      //   jogador:jogador,
      //   torneioId:torneioId
      // }

      const request = { torneio_id: torneioId,  jogador:jogador }
      const response = await api.post('report/getPotesUser', request );
     setPotes(response.data)
     setRenderPotes(true)
    } catch (error) {
      setPotes({})
      setRenderPotes(false)
      console.error('Erro ao obter jogadores:', error);
      return [];
    }
  }

  const selectPlayer = async (jogador)=>{
    setJogadorSelect(jogador);
    await getPotesPlayer(jogador)

  }

  useEffect(() => {
    const getPlayers = async () => {
      try {
        const response = await api.get('get-players');
       setPlayers(response.data)
      } catch (error) {
        console.error('Erro ao obter jogadores:', error);
        return [];
      }
    };

    getPlayers();

  },[])

  return (
   <>
      <Typography variant="h4" gutterBottom>
        Selecione o jogador para visualizar o voto
      </Typography>

          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={jogadorSelectd}
          label="Jogadores"
          onChange={(e)=>selectPlayer(e.target.value)}
          fullWidth

        >

            {Object.keys(players).slice().sort().map((player) => (
                <MenuItem value={players[player].nome}>{players[player].nome}</MenuItem>
                
            
            ))}
        </Select>  

          {renderPotes ?(
              <Grid container spacing={2}>
              <Divider light />
              {Object.keys(potes).map((potNum) => (
                
                  <Grid item xs={12} sm={6} md={4} lg={2} key={potNum}>
                  <div >
                    <CardContent>
                      <Typography>
                      <Pot
                          key={potNum}
                          potNum={potNum}
                          playersInPot={potes[potNum]}
                          // onDrop={(e) => handleDrop(e, potNum)}
                          // onPlayerRemove={handlePlayerRemove}
              
                      />
                      </Typography>
                    </CardContent>
                  </div>
                </Grid> 
                ))}
            
           </Grid>

          ):(<>
            <card>
            <Typography variant="h6" gutterBottom>
                 Usuario não votou ou prazo ainda não foi concluido.
             </Typography>
            </card>
             
             </>)}

          
   
   
   
   </>
  );
};

export default VotePlayer;
