/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Team from "./Team";
import WaitList from "./WaitList";
import ConfirmationDialog from "./ConfirmationDialog";
import api from "../../api";
import EstatisticasJogadores from "./EstatisticasJogadores";
import { Simulate } from "react-dom/test-utils";
import Modal from "./Modal";
import SwapPlayersDialog from "./SwapPlayersDialog";
import TransferWithinAStationRoundedIcon from "@mui/icons-material/TransferWithinAStationRounded";
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import { useSound } from 'use-sound'; // Para gerenciar o som
// Estilo para as divs principais dos botões
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

`;

// Estilo para as divs individuais dos botões
const ButtonDiv = styled.div`
  margin: 0 10px;
  padding: 15px 30px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: ${(props) => props.bgColor};

  &:hover {
    opacity: 0.8;
  }
`;

const GameContainer = styled.div`
  padding: 20px;
  background: black;
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 123, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const TeamsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
`;

const TeamCard = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 45%; /* Ajuste conforme necessário */
`;

const TeamTitle = styled.h2`
  text-align: center;
  margin-bottom: 15px;
`;



const TimerContainer = styled.div`
  text-align: center;
  margin: 20px 0;
  color: white;
`;

const TimerDisplay = styled.div`
  font-size: 2rem;
`;

const Game = () => {
  const [gameData, setGameData] = useState(null);
  const [listWait, setListWait] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [winnerIndex, setWinnerIndex] = useState(null);
  const [lastWinnerTime1, setLastWinnerTime1] = useState(null);
  const [lastWinnerTime2, setLastWinnerTime2] = useState(null);

  const [simulacaoGameData, setSimulacaoGameData] = useState(null);
  const [simulacaoListWait, setSimulacaoListWait] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTeams, setModalTeams] = useState([]);
  const [modalWaitList, setModalWaitList] = useState([]);
  const [isSwapDialogOpen, setIsSwapDialogOpen] = useState(false);
  const [availablePlayers, setAvailablePlayers] = useState([]);



  const [time, setTime] = useState(0); // Tempo do cronômetro em segundos
  const [isRunning, setIsRunning] = useState(false); // Estado de execução do cronômetro
  const timerRef = useRef(null); // Referência para o intervalo do cronômetro

  const [playSound] = useSound('/path/to/sound.mp3'); // Caminho para o som

  // Função para atualizar o tempo
  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime >= 600) { // 600 segundos = 10 minutos
            clearInterval(timerRef.current);
            playSound(); // Toca o som quando atinge 10 minutos
            return prevTime;
          }
          return prevTime + 1;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current); // Limpa o intervalo ao desmontar
  }, [isRunning, playSound]);

  const handleStartTimer = () => setIsRunning(true);
  const handlePauseTimer = () => setIsRunning(false);
  const handleResetTimer = () => {
    setIsRunning(false);
    setTime(0);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const handleSimular = async (vencedor) => {
    await handleDefinirVencedor(vencedor, true);
    console.log("simulacaoGameData");
    console.log(simulacaoGameData);
    // setModalTeams(simulacaoGameData.partida.times);
    // setModalWaitList(simulacaoListWait);
    // setIsModalOpen(true);
  };

  const handleSimularEmpate = async () => {
    await handleTratarEmpate(true);
    // setModalTeams(simulacaoGameData.partida.times);
    // setModalWaitList(simulacaoListWait);
    // setIsModalOpen(true);
  };

  useEffect(() => {
    if (simulacaoGameData) {
      setModalTeams(simulacaoGameData.partida.times);
      setModalWaitList(simulacaoListWait);
      setIsModalOpen(true);
    }
  }, [simulacaoGameData, simulacaoListWait]);

  const fetchGameActive = async () => {
    setLoading(true);
    try {
      const response = await api.get("getGameActive");
      console.log("API Response:", response.data);

      if (
        response.data.partida &&
        Object.keys(response.data.partida).length !== 0
      ) {
        setGameData({ ...response.data }); // Crie uma nova referência para forçar a renderização
        setLastWinnerTime1(
          arraysAreEqual(
            response.data.ultimoVencedor,
            response.data.partida.times[0]
          )
        );
        setLastWinnerTime2(
          arraysAreEqual(
            response.data.ultimoVencedor,
            response.data.partida.times[1]
          )
        );
      }

      if (response.data.espera && response.data.espera.length > 0) {
        setListWait([...response.data.espera]); // Crie uma nova referência para forçar a renderização
      } else {
        setListWait([]); // Limpe a lista de espera se não houver jogadores
      }
    } catch (error) {
      console.error("Error fetching active game:", error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchGameActive();
  }, []);

  const fetchGameData = async (endpoint) => {
    setLoading(true);
    try {
      const response = await api.post(`${endpoint}`);

      setWinnerIndex(null); // Reset winner index
      setGameData(null); // Reinicialize o estado de gameData
      setListWait([]); // Reinicialize o estado de listWait
      await fetchGameActive(); // Assegure-se de que o estado foi atualizado após definir o vencedor
    } catch (error) {
      console.error("Error fetching game data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSortearTimes = () => {
    fetchGameData("sortearTimes");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSimulacaoGameData(null);
    setSimulacaoListWait([]);
  };

  const handleUpdateWaitList = async () => {
    setLoading(true);
    try {
      const response = await api.post("updateWaitList", {
        partidaId: gameData.partida._id,
      });

      if (response.status === 200) {
        setListWait([]); // Reinicialize o estado de listWait
        await fetchGameActive(); // Assegure-se de que o estado foi atualizado após definir o vencedor
      }
    } catch (error) {
      console.error("Error update list wait:", error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdatePlayerPosition = async (direction,user_name) => {
    setLoading(true);
    try {
      const response = await api.post("changePositionWaitList", {
        partidaId: gameData.partida._id,
        user_name: user_name,
        direction:direction
      });

      if (response.status === 200) {
        setListWait([]); // Reinicialize o estado de listWait
        await fetchGameActive(); // Assegure-se de que o estado foi atualizado após definir o vencedor
      }
    } catch (error) {
      console.error("Error update list wait:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDefinirVencedor = async (vencedor, simulacao) => {
    setLoading(true);
    try {
      const response = await api.post("definirVencedor", {
        partidaId: gameData.partida._id,
        vencedor: vencedor,
        simulacao: simulacao,
      });
      if (response.status === 200) {
        if (simulacao) {
          if (
            response.data.partida &&
            Object.keys(response.data.partida).length !== 0
          ) {
            setSimulacaoGameData(null);

            setSimulacaoGameData({ ...response.data }); // Crie uma nova referência para forçar a renderização
          }

          setSimulacaoListWait([]);
          if (response.data.espera && response.data.espera.length > 0) {
            setSimulacaoListWait([...response.data.espera]); // Crie uma nova referência para forçar a renderização
          } else {
            setSimulacaoListWait([]); // Limpe a lista de espera se não houver jogadores
          }
        } else {
          setWinnerIndex(vencedor);
          setGameData(null); // Reinicialize o estado de gameData
          setListWait([]); // Reinicialize o estado de listWait
          await fetchGameActive(); // Assegure-se de que o estado foi atualizado após definir o vencedor
        }
      }
    } catch (error) {
      console.error("Error defining winner:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTratarEmpate = async (simulacao) => {
    setLoading(true);
    try {
      const response = await api.post("tratarEmpate", {
        partidaId: gameData.partida._id,
        simulacao: simulacao,
      });

      if (response.status === 200) {
        if (simulacao) {
          if (
            response.data.partida &&
            Object.keys(response.data.partida).length !== 0
          ) {
            setSimulacaoGameData(null);

            setSimulacaoGameData({ ...response.data }); // Crie uma nova referência para forçar a renderização
          }

          setSimulacaoListWait([]);
          if (response.data.espera && response.data.espera.length > 0) {
            setSimulacaoListWait([...response.data.espera]); // Crie uma nova referência para forçar a renderização
          } else {
            setSimulacaoListWait([]); // Limpe a lista de espera se não houver jogadores
          }
        } else {
          setWinnerIndex(null); // Resetar o índice do vencedor

          // Reinicialize os estados de gameData e listWait para garantir que não mantenham dados antigos
          setGameData(null);
          setListWait([]);
          await fetchGameActive();
        }

        // Aguarde a atualização dos dados do jogo ativo

        // Verifique se o estado foi atualizado corretamente
        console.log("GameData após tratar empate:", gameData);
        console.log("ListWait após tratar empate:", listWait);
      }
    } catch (error) {
      console.error("Error handling draw:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDialog = (action) => {
    setDialogAction(() => action);
    setIsDialogOpen(true);
  };

  const executeDialogAction = () => {
    if (dialogAction) {
      dialogAction();
      setIsDialogOpen(false);
    }
  };

  const getTeamColors = (index) => {
    if (index === 0) {
      return winnerIndex === 0
        ? { bgColor: "#007bff", textColor: "#ffffff" }
        : { bgColor: "#007bff", textColor: "#ffffff" };
    } else {
      return winnerIndex === 1
        ? { bgColor: "#ffc107", textColor: "#000000" }
        : { bgColor: "#ffc107", textColor: "#000000" };
    }
  };

  const removePlayer = async (userId) => {
    setLoading(true);
    try {
      const response = await api.post("removerJogador", { userId });
      if (response.status === 200) {
        setGameData(response.data);
        // setMessage('Jogador removido com sucesso.');
        // setSeverity('success');
        // setOpenMessage(true);
        // Reinicialize os estados de gameData e listWait para garantir que não mantenham dados antigos
        setGameData(null);
        setListWait([]);

        // Aguarde a atualização dos dados do jogo ativo
        await fetchGameActive();
      }
    } catch (error) {
      console.error("Error removing player:", error);
      //   setMessage(error.response.data.error || 'Erro ao remover jogador.');
      //   setSeverity('error');
      //   setOpenMessage(true);
    } finally {
      setLoading(false);
    }
  };

  function arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) return false;
    return array1.every(
      (player, index) => player.user_name === array2[index].user_name
    );
  }

  // Função para abrir o diálogo de troca de jogadores
  const openSwapDialog = () => {
    const players = [
      ...gameData.partida.times[0],
      ...gameData.partida.times[1],
      ...listWait,
    ];
    setAvailablePlayers(players); // Atualize com a lista de espera
    setIsSwapDialogOpen(true);
  };

  // Função para confirmar a troca de jogadores
  const handleSwapPlayers = async (playerOut, playerIn) => {
    try {
      setLoading(true);
      const time1 = gameData.partida.times[0];
      const time2 = gameData.partida.times[1];
      const outTime1 = time1.find((jogador) => jogador.user_name === playerOut);
      const outTime2 = time2.find((jogador) => jogador.user_name === playerOut);
      let timeIndex = null;
      if (outTime1 !== undefined) {
        timeIndex = 0;
      } else if (outTime2 !== undefined) {
        timeIndex = 1;
      } else {
        alert("Jogador " + playerOut + " não esta em jogo!");
        return;
      }

      const inTime1 = listWait.find((jogador) => jogador.user_name === playerIn);
      if (inTime1 == undefined) {
        alert("Jogador " + playerIn + " não esta na lista de espera!");
        return;
      }
   

      await api.post("changePlayer", {
        partidaId: gameData.partida._id,
        timeIndex,
        jogadorSaidaNome: playerOut,
        jogadorEntradaNome: playerIn,
      });
      await fetchGameActive(); // Atualiza o estado após a troca
    } catch (error) {
      console.error("Erro ao trocar jogadores:", error);
    } finally {
      setLoading(false);
      setIsSwapDialogOpen(false);
    }
  };

  return (
    <GameContainer>
      {/* Cronômetro */}
      <TimerContainer>
        <TimerDisplay>{formatTime(time)}</TimerDisplay>
        <ButtonContainer>
          <ButtonDiv bgColor="#007bff" onClick={handleStartTimer}>
            <PlayArrowIcon />
            Iniciar
          </ButtonDiv>
          <ButtonDiv bgColor="#ffc107" onClick={handlePauseTimer}>
            <PauseIcon />
            Pausar
          </ButtonDiv>
          <ButtonDiv bgColor="#dc3545" onClick={handleResetTimer}>
            <StopIcon />
            Zerar
          </ButtonDiv>
        </ButtonContainer>
      </TimerContainer>
      {!gameData && !loading && (
        <Button onClick={handleSortearTimes}>Sortear Times</Button>
      )}
      {loading && <Loader />}
      {gameData && (
        <>
          <TeamsContainer>
            <TeamCard {...getTeamColors(0)}>
              {lastWinnerTime1 ? (
                <TeamTitle>
                  Time 1
                  <div>
                    <img
                      style={{ maxWidth: "30%", padding: "3px" }}
                      src="./image/vitoria.png"
                    ></img>
                  </div>
                </TeamTitle>
              ) : (
                <TeamTitle>Time 1 </TeamTitle>
              )}
              <Team team={gameData.partida.times[0]} />
            </TeamCard>
            <TeamCard {...getTeamColors(1)}>
              {/* <TeamTitle>Time 2</TeamTitle> */}
              {lastWinnerTime2 ? (
                <TeamTitle>
                  Time 2
                  <div>
                    <img
                      style={{ maxWidth: "30%", padding: "3px" }}
                      src="./image/vitoria.png"
                    ></img>
                  </div>
                </TeamTitle>
              ) : (
                <TeamTitle>Time 2 </TeamTitle>
              )}

              <Team team={gameData.partida.times[1]} />
            </TeamCard>
          </TeamsContainer>
          <ButtonContainer>
            <ButtonDiv
              // bgColor="#007bff"
              onClick={() => openDialog(() => handleDefinirVencedor(0, false))}
            >
              Time 1 Venceu
              <img style={{ maxWidth: "100%" }} src="./image/vitoria.png"></img>
            </ButtonDiv>
            <ButtonDiv
              // bgColor="#28a745"
              onClick={() => openDialog(() => handleTratarEmpate(false))}
            >
              Empate
              <img style={{ maxWidth: "100%" }} src="./image/empate.png"></img>
            </ButtonDiv>

            <ButtonDiv
              // bgColor="#ffc107"
              onClick={() => openDialog(() => handleDefinirVencedor(1, false))}
            >
              Time 2 Venceu
              <img style={{ maxWidth: "100%" }} src="./image/vitoria.png"></img>
            </ButtonDiv>
          </ButtonContainer>
          <ButtonContainer>
            <ButtonDiv bgColor="#007bff" onClick={() => handleSimular(0)}>
              Simular Time 1 Venceu
            </ButtonDiv>
            <ButtonDiv bgColor="#28a745" onClick={handleSimularEmpate}>
              Simular Empate
            </ButtonDiv>
            <ButtonDiv bgColor="#ffc107" onClick={() => handleSimular(1)}>
              Simular Time 2 Venceu
            </ButtonDiv>
          </ButtonContainer>
          <ButtonContainer>
            <ButtonDiv bgColor="#D8BFD8" onClick={() => openSwapDialog()}>
              <TransferWithinAStationRoundedIcon />
              Trocar Jogadores =
            </ButtonDiv>
            <ButtonDiv bgColor="#D8BFD8" onClick={() => handleUpdateWaitList()}>
              <AutorenewRoundedIcon />
              Atualizar lista
            </ButtonDiv>
          </ButtonContainer>
          {gameData.venceuDuasVezes ? (
            <WaitList
              title={"Time 2 vitorias"}
              onRemovePlayer={removePlayer}
              waitList={gameData.venceuDuasVezes}
            />
          ) : (
            <></>
          )}
          <WaitList
            title={"Fila Espera"}
            onRemovePlayer={removePlayer}
            waitList={listWait}
            onUpdatePlayerPosition={onUpdatePlayerPosition}
          />
          <EstatisticasJogadores
            estatisticas={gameData.estatisticasJogadores}
          />
          ,
        </>
      )}
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={executeDialogAction}
      />
      <SwapPlayersDialog
        isOpen={isSwapDialogOpen}
        onClose={() => setIsSwapDialogOpen(false)}
        onConfirm={handleSwapPlayers}
        availablePlayers={availablePlayers}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => handleCloseModal()}
        teams={modalTeams}
        waitList={modalWaitList}
      />
    </GameContainer>
  );
};

export default Game;
