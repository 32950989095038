// src/components/PlayArrived.js
import React from 'react';
import './PlayArrived.css';
import formatDateToBrazilTime from '../../utils/dateUtils';
import { generateWhatsAppLink } from '../../utils/shareUtils';

const PlayArrived = ({ confirmedPlayers, arrivedPlayers, playArrived, removePlayer, play, loading }) => {

  const handleArrival = (player) => {
    const { user, guest, guestId } = player;
    const peladaId = play._id; 
    const payload = guest 
      ? { userId: user, peladaId, guestId: guestId }
      : { userId: user, peladaId };
    
    playArrived(payload, player);
  };

  const handleOut = (player) => {
    const { user, guest, guestId } = player;
    const peladaId = play._id; 
    const payload = guest 
      ? { userId: user, peladaId, guestId: guestId }
      : { userId: user, peladaId };
    
    removePlayer(payload);
  };

  return (
    <div className="player-lists">
      <div className="list confirmed-players">
        <h2>Jogadores Confirmados</h2>
        <ul>
          {confirmedPlayers.map(player => (
            <li key={player._id}>
              <span>
              <span>{player.user_name}</span>
              <p>Confirmação: {formatDateToBrazilTime(player.confirmationTime)}</p>
              <p style={{color:'red'}}>{player.devedor ? "Em Atraso" : ""}</p>
              <p style={{color:'red'}}>{player.typePlayer === 'D' ? "Diarista" : ""}</p>
                

              </span>
              <button className="arrival-button" onClick={() => handleArrival(player)}>{loading ? "Adicionando..." : "Chegou"}</button>
            </li>
          ))}
        </ul>
      </div>
      <div className="list arrived-players">
        <h2>Na Concentração</h2>
        <a 
          href={generateWhatsAppLink(arrivedPlayers)} 
          target="_blank" 
          rel="noopener noreferrer"
          className="whatsapp-share-link"
        >
          <img 
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
            alt="Compartilhar no WhatsApp" 
            style={{width: '30px', height: '30px'}}
          />
        </a>
        <ul>
          {arrivedPlayers.map((player, index) => (
            <li key={player._id}>
              <span>
                {index + 1}. {player.user_name}
                <p style={{color:'red'}}>{player.devedor ? "Em Atraso" : ""}</p>
                <p style={{color:'red'}}>{player.typePlayer === 'D' ? "Diarista" : ""}</p>
                <p>Confirmação: {formatDateToBrazilTime(player.confirmationTime)}</p>
                <p>Chegada: {formatDateToBrazilTime(player.arrivalTime)}</p>
              </span>
              <button className="remove-button" onClick={() => handleOut(player)}>Já foi</button>
            </li>
          ))}
        </ul>
       
      </div>
    </div>
  );
};

export default PlayArrived;