import React from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';


const PotContainer = styled.div`
  background-color: ${props => props.qtdPlayers===6? '#00FA9A': `#ADD8E6`};
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 150px;
  margin: 10px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
`;

const PotTitle = styled.h2`
  margin-bottom: 10px;
`;

const PlayerInPot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  background-color: #ff0019;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
`;

const Pot = ({ potNum, playersInPot, onDrop, onPlayerRemove,showRemove }) => {
  return (
    <PotContainer
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => onDrop(e, potNum)}
      
      qtdPlayers = {playersInPot.length}
    >
      <PotTitle>Pote {potNum} ({playersInPot.length})</PotTitle>
      {playersInPot.map((player, index) => (
        <PlayerInPot key={index}>
          {player}
          <Divider/>
          <Tooltip title="excluir">
            {showRemove?( <RemoveButton onClick={(e) => onPlayerRemove(e, potNum, player)}>X</RemoveButton>):(<></>)}
         

          </Tooltip>
        </PlayerInPot>
      ))}
    </PotContainer>
  );
};

export default Pot;
