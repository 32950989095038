import React, { useEffect } from 'react';
import './EstatisticasJogadores.css';
import styled from 'styled-components';



const EstatisticasJogadores = ({ estatisticas, fetchGameActive }) => {

    const WaitListTitle = styled.h2`
  text-align: center;
  margin-bottom: 15px;
  color: blueviolet;
`;

useEffect(() => {
if(fetchGameActive!==undefined)
  fetchGameActive()
}, []);

  return (
    <div className="estatisticas-container">
      <WaitListTitle>Estatísticas dos Jogadores</WaitListTitle>
      <div className="estatisticas-tabela">
        {Object.keys(estatisticas).map((userId) => (
          <div className="estatisticas-card" key={userId}>
            <div className="estatisticas-row">
              <span className="estatisticas-label">Nome:</span>
              <span className="estatisticas-value">{estatisticas[userId].user_name}</span>
            </div>
            <div className="estatisticas-row">
              <span className="estatisticas-label">Vitórias:</span>
              <span className="estatisticas-value vitorias">{estatisticas[userId].vitorias}</span>
            </div>
            <div className="estatisticas-row">
              <span className="estatisticas-label">Derrotas:</span>
              <span className="estatisticas-value derrotas">{estatisticas[userId].derrotas}</span>
            </div>
            <div className="estatisticas-row">
              <span className="estatisticas-label">Empates:</span>
              <span className="estatisticas-value">{estatisticas[userId].empates}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EstatisticasJogadores;
