import React from "react";
import styled from "styled-components";

const PlayerContainer = styled.div`
  margin: 10px 0;
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PlayerInfoContainer = styled.div`
  flex: 1;
`;

const PlayerName = styled.p`
  font-size: 1.2em;
  font-weight: bold;
  color: #007bff;
`;

const PlayerInfo = styled.p`
  color: #555;
`;

const PlayerImageContainer = styled.div`
  margin-left: 10px;
  text-align: center;
`;

const PlayerImage = styled.img`
  width: 50px; /* Ajuste o tamanho da imagem conforme necessário */
  height: auto;
`;

const PlayerText = styled.p`
  margin: 5px 0 0;
  color: #555;
`;

const Player = ({ player }) => (
  <PlayerContainer>
    <PlayerInfoContainer>
      <PlayerName>{player.user_name}</PlayerName>
      <PlayerInfo>Posição Original: {player.posicaoOriginal}</PlayerInfo>
      <PlayerInfo>Posição na Fila: {player.posicaoFila}</PlayerInfo>
      <PlayerInfo>Jogos: {player.jogos}</PlayerInfo>
    </PlayerInfoContainer>
    <PlayerImageContainer>
      {player.team !== null && player.team !==undefined  && (
        <>
          <PlayerImage
            src={`/times/${player.team.replaceAll(" ", "_")}.png`}
            alt={player.team} // Use o nome do time como alt para melhor acessibilidade
            Bayern de Munique
          />
          <PlayerText>Pote {player.pote}</PlayerText>
        </>
      )}
    </PlayerImageContainer>
  </PlayerContainer>
);

export default Player;
