import React from 'react';
import styled from 'styled-components';

// Estilo para o seletor de jogadores
const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const PlayerSelector = ({ players, selectedPlayer, onSelect, type }) => {
  return (
    <SelectorContainer>
      <Label>Escolha um jogador: {type}</Label>
      <Select value={selectedPlayer} onChange={(e) => onSelect(e.target.value)}>
        <option value="">Selecione um jogador</option>
        {players.map((player) => (
          <option key={player.user_name} value={player.user_name}>
            {player.user_name}
          </option>
        ))}
      </Select>
    </SelectorContainer>
  );
};

export default PlayerSelector;
