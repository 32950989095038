import styled from "styled-components";

export const  CardContent = styled.div`

background-color:red ;
`;

export const DivContainer = styled.div`
  background: #ffff;

  max-width: 100%;
  align-self: center;
  width: 100%;
  display: flex;
  height: 100vh;
  

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const DivLogo = styled.div`
  max-width: 50%;
  /* background-color: cadetblue; */
  border-right-style: solid;
  border-width: 1px;

  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* height: 1000px; */

  span {
    font-size: 20px;
    color: black;
    width: 60%;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    width: 80%;
    max-width: 80%;
    align-items: center;
    justify-content: center;
    border-right-style: none;

    img {
      height: 100px;
    }
  }
`;

export const DivForm = styled.div`
  max-width: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content:center;
  width: 50%;
   form {
    width:80%;
    display: flex;
    flex-direction: column;
   
    /* background-color: #BA55D3; */
    align-items: center;

    input {
      background: #fff;
      border:none;
      border-bottom-style:solid;
      border-bottom-width: 0.5px;
      border-bottom-color:#BEBEBE;
      
      height: 50px;
      padding: 0 15px;
      color:#BEBEBE;
      margin: 0 0 10px;
      width: 80%;
      font-size:15px;
      &::placeholder {
        color:#BEBEBE;
        text-align:center;
      }
      &:focus {
        background: #F8F8FF;
        border-style: none;
      }
    }

    button {
      margin-top: 5px;
      margin-bottom: 50px;

      height: 50px;

      color: #fff;
      border: 0;
      border-radius: 10px;

      transition: background 0.25s;
      width: 80%;
        background: #72d9ec;

      &:hover {
      background: #8A2BE2;
      }
    }

    a {
      color: #7c61f9;
      margin-top: 10px;
      font-size: 12px;
      opacity: 0.5;
      margin-left: 20px;
      text-align:left;
      align-items:left;  
      &:hover {
        opacity: 2;
      }
    }

    span {
      color: black;
      /* ali: flex-start; */
      margin-left:30px;
      margin-bottom:5px;
      font-display: bold;
      font-weight:bold;
      font-size:10px;
      color:red;
      
    }
    
  }

   @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    align-items: center;
    form {
     width:100%;
     display: flex;
     flex-direction: column;
     align-items: center;
        input {
        color:#BEBEBE;
     }

        button {
         width: 100%;
   
        }

    }
  }
`;

export const DivLink = styled.div`
  display: flex;
  justify-content: flex-end;
  /* background-color: red; */
  width: 80%;
`;

export const DialogTitleStyled = styled.h2`
  margin: 0;
  padding: 16px;
  background-color: #1976d2;
  color: white;
`;

export const DialogContentStyled = styled.div`
  padding: 16px;
  width: 300px;
`;

export const DialogActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;