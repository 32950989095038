import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import api from '../../api';
import Header from '../../components/Header';

const PageWrapper = styled.div`
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom right, #3a3a3a, #1a1a1a);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const Container = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 15px;
    box-shadow: none;
    margin: 20px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 15px;
  .react-select__control {
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #ccc;
    &:focus {
      border-color: #007bff;
    }
  }
`;

const NativeSelect = styled.select`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Message = styled.div`
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  color: ${(props) => (props.error ? 'red' : 'green')};
`;

const countries = [
  // América do Sul
  { value: "AR", label: "Argentina" },
  { value: "BO", label: "Bolívia" },
  { value: "BR", label: "Brasil" },
  { value: "CL", label: "Chile" },
  { value: "CO", label: "Colômbia" },
  { value: "EC", label: "Equador" },
  { value: "GY", label: "Guiana" },
  { value: "PY", label: "Paraguai" },
  { value: "PE", label: "Peru" },
  { value: "SR", label: "Suriname" },
  { value: "UY", label: "Uruguai" },
  { value: "VE", label: "Venezuela" },
  // Europa
  { value: "AL", label: "Albânia" },
  { value: "DE", label: "Alemanha" },
  { value: "AD", label: "Andorra" },
  { value: "AM", label: "Armênia" },
  { value: "AT", label: "Áustria" },
  { value: "BY", label: "Bielorrússia" },
  { value: "BE", label: "Bélgica" },
  { value: "BA", label: "Bósnia e Herzegovina" },
  { value: "BG", label: "Bulgária" },
  { value: "HR", label: "Croácia" },
  { value: "DK", label: "Dinamarca" },
  { value: "SK", label: "Eslováquia" },
  { value: "SI", label: "Eslovênia" },
  { value: "ES", label: "Espanha" },
  { value: "EE", label: "Estônia" },
  { value: "FI", label: "Finlândia" },
  { value: "FR", label: "França" },
  { value: "GE", label: "Geórgia" },
  { value: "GR", label: "Grécia" },
  { value: "HU", label: "Hungria" },
  { value: "IS", label: "Islândia" },
  { value: "IT", label: "Itália" },
  { value: "XK", label: "Kosovo" },
  { value: "LV", label: "Letônia" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lituânia" },
  { value: "LU", label: "Luxemburgo" },
  { value: "MT", label: "Malta" },
  { value: "MD", label: "Moldávia" },
  { value: "MC", label: "Mônaco" },
  { value: "ME", label: "Montenegro" },
  { value: "NO", label: "Noruega" },
  { value: "NL", label: "Países Baixos" },
  { value: "PL", label: "Polônia" },
  { value: "PT", label: "Portugal" },
  { value: "UK", label: "Reino Unido" },
  { value: "CZ", label: "República Tcheca" },
  { value: "RO", label: "Romênia" },
  { value: "RU", label: "Rússia" },
  { value: "SM", label: "San Marino" },
  { value: "RS", label: "Sérvia" },
  { value: "SE", label: "Suécia" },
  { value: "CH", label: "Suíça" },
  { value: "UA", label: "Ucrânia" },
  { value: "VA", label: "Vaticano" },
];

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    nome: '',
    pais: '',
    numeroCamisa: '',
    tamanhoCamisa: '',
    nomeConvidado: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, pais: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post('createSubscription', formData);
      setMessage(response.data.message);
      setError(false);
      setTimeout(() => {
        navigate('/pagamentoInscricao');
      }, 2000);
    } catch (err) {
      setMessage(err.response.data.error || 'Erro ao realizar inscrição.');
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    
        <Header/>
    <PageWrapper>
      <Container>
      <Image src="/image/logo_rfc.png" alt="Torneio de Futebol" />

        <Title>Inscrição para o Torneio</Title>
        <Form onSubmit={handleSubmit}>
          <Label>Nome camisa</Label>
          <Input type="text" name="nome" value={formData.nome} onChange={handleChange} required />
          
          <Label>País</Label>
          <StyledSelect
            name="pais"
            options={countries}
            onChange={handleCountryChange}
            placeholder="Selecione o País"
            isSearchable
            required
          />
          
          <Label>Número da Camisa</Label>
          <Input type="number" name="numeroCamisa" value={formData.numeroCamisa} onChange={handleChange} required />
          
          <Label>Tamanho da Camisa</Label>
          <NativeSelect name="tamanhoCamisa" value={formData.tamanhoCamisa} onChange={handleChange} required>
            <option value="">Selecione o Tamanho</option>
            <option value="P">P</option>
            <option value="M">M</option>
            <option value="G">G</option>
            <option value="GG">GG</option>
          </NativeSelect>
          
          <Label>Nome do Convidado (Opcional)</Label>
          <Input type="text" name="nomeConvidado" value={formData.nomeConvidado} onChange={handleChange} />
          
          <Button type="submit" disabled={loading}>Inscrever</Button>
        </Form>
        {loading && <p>Carregando...</p>}
        {message && <Message error={error}>{message}</Message>}
      </Container>
    </PageWrapper>
    </>
  );
};

export default RegistrationPage;