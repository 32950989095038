import React, { useState, useRef, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext'; // Substitua pelo caminho correto
import { DivContainer, DivLogo, DivForm, DivLink } from "./styles";
import { Form, Input } from "@rocketseat/unform";
import api from '../../api';


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import * as Yup from "yup";

import logo from '../../images/logo_rfc.jpg.png';

const schema = Yup.object().shape({
    email: Yup.string().email("E-mail invalido").required("E-mail obrigatório"),
    password: Yup.string().required("Senha obrigatória"),
  });

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  const [nome, setNome] = useState('');

  const [email, setEmail] = useState('');

  const [apartamento, setApartamento] = useState('');

  const [torre, setTorre] = useState('');
  


  const { isAuthenticated, login } = useAuth();

  const [loading, setLoading] = useState(false);


  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogOpenReset, setDialogOpenReset] = useState(false);

  const [severity, setSeverity] = useState('success');

 const [openMessage, setOpenMessage] = useState(false);
 const [message, setMessage] = useState('');

 
  

  const handleLogin = async (data) => {
    // Lógica de autenticação aqui
    // Após autenticação bem-sucedida, chame login();

    const { email, password } = data;
    setPassword(password)
    setUsername(email);
    setLoading(true);
    try {
    const response = await login(email, password);

    if(!response.isAuthenticated){
      setMessage(response.message)
      setSeverity('error')
      setOpenMessage(true);
    }
      
    } catch (error) {
      
      
      setMessage('erro inesperado')
      setSeverity('error')
      setOpenMessage(true);
    }
    setLoading(false);
  };

  function handleOpen() {
    setDialogOpen(true);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  function handleOpenReset() {
    setDialogOpenReset(true);
  }

  const handleCloseReset = () => {
    setDialogOpenReset(false);
  };

  const handleCloseSuccess =()=>{
    setOpenMessage(false);
  }

  function validarCampos(email, password, nome, torre, apartamento) {
    // Expressão regular para validar e-mails
    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
  
    // Verificar se o e-mail é válido
    if (!email.match(emailRegex)) {
      return "E-mail inválido";
    }
  
    // Verificar se o nome possui pelo menos 2 palavras
    const nomeParts = nome.trim().split(" ");
    if (nomeParts.length < 2) {
      return "Nome completo deve conter pelo menos 2 palavras";
    }
  
    // Verificar se a torre está presente
    if (!torre) {
      return "Campo Torre é obrigatório";
    }
  
    // Verificar se o apartamento está presente
    if (!apartamento) {
      return "Campo Apartamento é obrigatório";
    }
  
    // Verificar se a senha é obrigatória e possui complexidade média
    if (!password) {
      return "Campo Senha é obrigatório";
    }
  
    // A senha deve ter pelo menos 8 caracteres e conter letras maiúsculas, minúsculas e números
    const senhaRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!password.match(senhaRegex)) {
      return "Senha deve conter pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas e números";
    }
  
    // Se todos os campos passaram nas verificações, retorna null para indicar que são válidos
    return null;
  }

  const cadastrarUsuario= async ()=>{
     
   const result = await validarCampos(email, password, nome, torre, apartamento)

    if(result){
      setMessage(result)
      setSeverity('error')
      setOpenMessage(true);
      return;
    }

      try {

        const  body ={
            email: email,
            nome: nome,
            torre: torre,
            apartamento: apartamento,
            password:password
        }
        const response = await api.post('user/store', body);
        console.log(response)
        if(response.status==200){
          setDialogOpen(false);
          setMessage("Usuario Cadastrado com sucesso.")
          setSeverity('success')
          setOpenMessage(true);
        }else{
          setMessage(response.data.error)
          setSeverity('error')
          setOpenMessage(true);
        }
        
      } catch (error) {
        console.error('Erro ao logar', error);
        setMessage(error.response.data.error?error.response.data.error:'Ocorreu um erro')
        setSeverity('error')
        setOpenMessage(true);
       
      }

  }

  const resetPassword= async ()=>{
    console.log(email)
    console.log(nome)
    console.log(torre)
    console.log(apartamento)

    try {

      const  body ={
          email: email,
          
      }
      const response = await api.post('user/reset', body);
      console.log(response)
      if(response.status==200){
        setDialogOpenReset(false);
        setMessage("E-mail para redefinição enviado verifique seu email.")
        setSeverity('success')
        setOpenMessage(true);
      }else{
        setMessage(response.data.error)
        setSeverity('error')
        setOpenMessage(true);
      }
      
    } catch (error) {
      console.error('Erro ao logar', error);
      setMessage(error.response.data.error?error.response.data.error:'Ocorreu um erro')
      setSeverity('error')
      setOpenMessage(true);
     
    }
}



  if (isAuthenticated) {
    return <Navigate to="/painel" />;
  }

  return (
    

    <DivContainer>

      <Snackbar open={openMessage} onClose={handleCloseSuccess} autoHideDuration={6000} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} >
        <MuiAlert elevation={6}  variant="filled" severity={severity} sx={{ width: '100%' }}>
          {message}
        </MuiAlert>
      </Snackbar>
    <DivLogo
      // style={{
      //   backgroundImage: `url(${background})`,
      // }}
    >
      <img style={{ opacity: "80%" }} src={logo} alt="RFC" />
    </DivLogo>
    <DivForm>
      <Form schema={schema} onSubmit={handleLogin}>
        <div style={{ marginBottom: 50, fontFamily: "cursive" }}>
          <h1>Login</h1>
        </div>
        <Input name="email" placeholder="Usuário" type="email"></Input>

        <Input name="password" placeholder="Senha" type="password"></Input>
        <DivLink>
          <a onClick={handleOpenReset}>Esqueci a senha.</a>
        </DivLink>
        <button type="submit">{loading ? "carregando..." : "Entrar"}</button>
        <DivLink>
          <a onClick={handleOpen}>Não possui Cadastro? Clique aqui!</a>
        </DivLink>
      </Form>
    </DivForm>
    {/* <ModalResetPassword open={open} handleClose={handleClose} handleOpen /> */}


    <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Cadastro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe os dados para cadastro.
            - você recebera um e-mail com a sua senha.
          </DialogContentText>
          
          <TextField
                 margin="dense"
                 id="email"
                 label="Email Address"
                 type="email"
                 fullWidth
                 variant="standard"
                 onChange={(e)=>setEmail(e.target.value)}
          />

          <TextField
                 margin="dense"
                 id="password"
                 label="senha"
                 type="password"
                 fullWidth
                 variant="standard"
                 onChange={(e)=>setPassword(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="nome"
            label="Nome Completo"
            
            fullWidth
            variant="standard"
            onChange={(e)=>setNome(e.target.value)}
          />
          <InputLabel id="demo-simple-select-label">Torre</InputLabel>
              <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={age}
          label="Torre"
          onChange={(e)=>setTorre(e.target.value)}
          fullWidth

        >
          <MenuItem value={'Acqua'}>Acqua</MenuItem>
          <MenuItem value={'Natura'}>Natura</MenuItem>
   
        </Select>    
             
        <TextField
            margin="dense"
            id="apartamento"
            label="Numero Apartamento"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e)=>setApartamento(e.target.value)}
          />  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancelar</Button>
          <Button onClick={cadastrarUsuario}>Cadastrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpenReset} onClose={handleCloseReset}>
        <DialogTitle>Reset de senha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            informe seu email de cadastro
            - você recebera um e-mail com a sua senha.
          </DialogContentText>
          <TextField
                 margin="dense"
                 id="email"
                 label="Email Address"
                 type="email"
                 fullWidth
                 variant="standard"
                 onChange={(e)=>setEmail(e.target.value)}
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset}>cancelar</Button>
          <Button onClick={resetPassword}>Solicitar Redefinição</Button>
        </DialogActions>
      </Dialog>
  </DivContainer>
  );
};

export default Login;
