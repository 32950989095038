import axios from "axios";

// const api = axios.create({
//   // baseURL: "http://localhost:3002",
//   baseURL: "https://rivierafc-api.onrender.com",
//   // baseURL: "https://rivierafc-api-1.onrender.com",

// });

const getApi = axios.create({
  // baseURL: "http://localhost:3002",
  baseURL: "https://rivierafc-api.onrender.com",
});

async function post(url, body) {

  await addToken(getApi)
  return getApi.post(url, body)
}

async function get(url, body) {
  const api = getApi;
  await addToken(getApi)
 return getApi.get(url, body)
}

async function put(url, body) {
  await addToken(getApi)
  return getApi.put(url, body)
}

async function remove(url, body) {
  await addToken(getApi)
  return  getApi.delete(url, body)
}

async function addToken(api) {
  const storedToken = JSON.parse(localStorage.getItem('token'));
  if (storedToken && storedToken !== "\"\"") {
    console.log("token adicionado")

    api.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
    return api
  }else{
    console.log("não existe token")
  }
  return api;
}


export default ({getApi,post, get,remove,put});
