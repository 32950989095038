import React from 'react';

function PoliticasUso() {
  return (
    <div>
    <h2>Política de Privacidade do Aplicativo Riviera FC</h2>

    <div>
        <h2>1. Introdução</h2>
        <p>
            O aplicativo Riviera FC ("Aplicativo" ou "Nós") é um aplicativo móvel que fornece aos usuários acesso a informações sobre o time de futebol Riviera FC, incluindo notícias, estatísticas, escalações, jogos, ingressos e muito mais. O Aplicativo também permite que os usuários interajam com outros fãs, participem de concursos e promoções e comprem produtos do time.
        </p>
        <p>
            Esta Política de Privacidade ("Política") descreve como coletamos, usamos e divulgamos suas informações pessoais quando você usa o Aplicativo. Ao usar o Aplicativo, você concorda com os termos desta Política. Se você não concordar com os termos desta Política, por favor, não use o Aplicativo.
        </p>
    </div>

    <div>
        <h2>2. Coleta de Informações Pessoais</h2>
        <p>Coletamos as seguintes informações pessoais de você:</p>
        <p>
            <strong>Informações de Conta:</strong> Quando você cria uma conta no Aplicativo, coletamos seu nome, endereço de e-mail, senha e data de nascimento.
        </p>
        <p>
            <strong>Informações do Perfil:</strong> Você pode optar por fornecer informações adicionais ao seu perfil, como sua foto, localização e interesses.
        </p>
        <p>
            <strong>Informações de Uso:</strong> Coletamos informações sobre como você usa o Aplicativo, como as páginas que você visita, os recursos que você usa e seu tempo gasto no Aplicativo.
        </p>
        <p>
            <strong>Informações do Dispositivo:</strong> Coletamos informações sobre o dispositivo que você usa para acessar o Aplicativo, como o sistema operacional, o modelo do dispositivo e o endereço IP.
        </p>
        <p>
            <strong>Informações de Pagamento:</strong> Se você comprar produtos ou serviços através do Aplicativo, coletamos suas informações de pagamento, como o número do seu cartão de crédito e o endereço de cobrança.
        </p>
    </div>

    <div>
        <h2>3. Uso de Informações Pessoais</h2>
        <p>Usamos suas informações pessoais para os seguintes fins:</p>
        <p>
            <strong>Fornecer e melhorar o Aplicativo:</strong> Usamos suas informações pessoais para fornecer e melhorar o Aplicativo, incluindo a personalização da sua experiência e a exibição de conteúdo relevante para você.
        </p>
        <p>
            <strong>Comunicar-se com você:</strong> Usamos suas informações pessoais para nos comunicar com você sobre o Aplicativo, como para enviar atualizações, notificações e ofertas promocionais.
        </p>
        <p>
            <strong>Analisar o uso do Aplicativo:</strong> Usamos suas informações pessoais para analisar o uso do Aplicativo e identificar tendências.
        </p>
        <p>
            <strong>Prevenir fraudes e abusos:</strong> Usamos suas informações pessoais para prevenir fraudes e abusos no Aplicativo.
        </p>
    </div>

    <div>
        <h2>4. Compartilhamento de Informações Pessoais</h2>
        <p>Não compartilhamos suas informações pessoais com terceiros, exceto nos seguintes casos:</p>
        <p>
            <strong>Com seu consentimento:</strong> Podemos compartilhar suas informações pessoais com terceiros com seu consentimento.
        </p>
        <p>
            <strong>Com fornecedores de serviços:</strong> Podemos compartilhar suas informações pessoais com fornecedores de serviços que nos ajudam a operar o Aplicativo, como provedores de hospedagem, provedores de análise e provedores de pagamento.
        </p>
        <p>
            <strong>Para cumprir a lei:</strong> Podemos compartilhar suas informações pessoais com as autoridades governamentais ou outras partes, se formos obrigados a fazê-lo por lei ou se acreditarmos de boa fé que tal ação seja necessária para proteger nossos direitos ou os direitos de terceiros.
        </p>
    </div>

    <div>
        <h2>5. Segurança de Informações Pessoais</h2>
        <p>
            Tomamos medidas de segurança para proteger suas informações pessoais contra perda, uso indevido, acesso não autorizado, divulgação, alteração ou destruição. No entanto, nenhuma medida de segurança é perfeita e não podemos garantir que suas informações pessoais não serão acessadas ou usadas de forma indevida.
        </p>
    </div>

    <div>
        <h2>6. Suas Escolhas</h2>
        <p>Você pode controlar suas informações pessoais e como elas são usadas da seguinte forma:</p>
        <p>
            <strong>Acessar e atualizar suas informações pessoais:</strong> Você pode acessar e atualizar suas informações pessoais na página de configurações do seu perfil.
        </p>
        <p>
            <strong>Cancelar sua conta:</strong> Você pode cancelar sua conta a qualquer momento enviando um e-mail para suporte@rivierafc.com.br.
        </p>
        <p>
            <strong>Desativar a coleta de informações:</strong> Você pode desativar a coleta de informações de localização e outras informações do dispositivo nas configurações do seu dispositivo.
        </p>
    </div>

    <div>
        <h2>7. Mudanças nesta Política</h2>
        <p>
            Podemos atualizar esta Política de tempos em tempos. Se fizermos alterações materiais a esta Política, notificaremos você por e-mail ou através do Aplicativo.
        </p>
    </div>

    <div>
        <h2>8. Contato</h2>
        <p>
            Se você tiver alguma dúvida sobre esta Política, por favor, entre em contato conosco pelo e-mail suporte@rivierafc.com.br.
        </p>
    </div>

    <div>
        <h2>9. Data de vigência</h2>
        <p>
            Esta Política foi atualizada pela última vez em 5 de junho de 2024.
        </p>
    </div>
</div>
  );
}

export default PoliticasUso;
