import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from 'react-select';

const countries = [
    // América do Sul
    { value: "AR", label: "Argentina" },
    { value: "BO", label: "Bolívia" },
    { value: "BR", label: "Brasil" },
    { value: "CL", label: "Chile" },
    { value: "CO", label: "Colômbia" },
    { value: "EC", label: "Equador" },
    { value: "GY", label: "Guiana" },
    { value: "PY", label: "Paraguai" },
    { value: "PE", label: "Peru" },
    { value: "SR", label: "Suriname" },
    { value: "UY", label: "Uruguai" },
    { value: "VE", label: "Venezuela" },
    // Europa
    { value: "AL", label: "Albânia" },
    { value: "DE", label: "Alemanha" },
    { value: "AD", label: "Andorra" },
    { value: "AM", label: "Armênia" },
    { value: "AT", label: "Áustria" },
    { value: "BY", label: "Bielorrússia" },
    { value: "BE", label: "Bélgica" },
    { value: "BA", label: "Bósnia e Herzegovina" },
    { value: "BG", label: "Bulgária" },
    { value: "HR", label: "Croácia" },
    { value: "DK", label: "Dinamarca" },
    { value: "SK", label: "Eslováquia" },
    { value: "SI", label: "Eslovênia" },
    { value: "ES", label: "Espanha" },
    { value: "EE", label: "Estônia" },
    { value: "FI", label: "Finlândia" },
    { value: "FR", label: "França" },
    { value: "GE", label: "Geórgia" },
    { value: "GR", label: "Grécia" },
    { value: "HU", label: "Hungria" },
    { value: "IS", label: "Islândia" },
    { value: "IT", label: "Itália" },
    { value: "XK", label: "Kosovo" },
    { value: "LV", label: "Letônia" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lituânia" },
    { value: "LU", label: "Luxemburgo" },
    { value: "MT", label: "Malta" },
    { value: "MD", label: "Moldávia" },
    { value: "MC", label: "Mônaco" },
    { value: "ME", label: "Montenegro" },
    { value: "NO", label: "Noruega" },
    { value: "NL", label: "Países Baixos" },
    { value: "PL", label: "Polônia" },
    { value: "PT", label: "Portugal" },
    { value: "UK", label: "Reino Unido" },
    { value: "CZ", label: "República Tcheca" },
    { value: "RO", label: "Romênia" },
    { value: "RU", label: "Rússia" },
    { value: "SM", label: "San Marino" },
    { value: "RS", label: "Sérvia" },
    { value: "SE", label: "Suécia" },
    { value: "CH", label: "Suíça" },
    { value: "UA", label: "Ucrânia" },
    { value: "VA", label: "Vaticano" },
];


const pergunta = [
    // América do Sul
    { value: "SIM", label: "SIM" },
    { value: "NÃO", label: "NÃO" }
]

    const PlayerFormDialog = ({ open, onClose, player, onSave }) => {
        const [formData, setFormData] = useState({});
    
        // Use useEffect para definir o formData quando o player mudar
        useEffect(() => {
            if (player) {
                setFormData(player);
            } else {
                setFormData({});
            }
        }, [player]);
    
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        };
    
        const handleCountryChange = (selectedOption) => {
            setFormData({ ...formData, pais: selectedOption.value });
        };

        const handlePagoChange = (selectedOption) => {
            setFormData({ ...formData, pago: selectedOption.value });
        };
        const handleFestaChange = (selectedOption) => {
            setFormData({ ...formData, festa: selectedOption.value });
        };
        
        const handleSubmit = () => {
            onSave(formData);
        };
    
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{player ? 'Editar Jogador' : 'Adicionar Jogador'}</DialogTitle>
                {formData ? (
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="Nome"
                            type="text"
                            fullWidth
                            name="nome"
                            value={formData.nome || ''}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            label="Código de Votação"
                            type="text"
                            fullWidth
                            name="codigoVotacao"
                            value={formData.codigoVotacao || ''}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            label="Número da Camisa"
                            type="number"
                            fullWidth
                            name="numeroCamisa"
                            value={formData.numeroCamisa || ''}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            label="Tamanho da Camisa"
                            type="text"
                            fullWidth
                            name="tamanhoCamisa"
                            value={formData.tamanhoCamisa || ''}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            label="Nome do Convidado"
                            type="text"
                            fullWidth
                            name="nomeConvidado"
                            value={formData.nomeConvidado || ''}
                            onChange={handleChange}
                        />
                        <Select
                            name="pais"
                            options={countries}
                            onChange={handleCountryChange}
                            value={countries.find(option => option.value === formData.pais) || null}
                            placeholder="Selecione o País"
                            isSearchable
                        />
                            <Select
                            name="Pago"
                            options={pergunta}
                            onChange={handlePagoChange}
                            value={pergunta.find(option => option.value === formData.pago) || null}
                            placeholder="Pagou?"
                            isSearchable
                        />
                        
                            <Select
                            name="Pago"
                            options={pergunta}
                            onChange={handleFestaChange}
                            value={pergunta.find(option => option.value === formData.festa) || null}
                            placeholder="Vai para festa?"
                            isSearchable
                        />
                    </DialogContent>
                ) : null}
               
                <DialogActions>
                    <Button onClick={onClose} color="secondary">Cancelar</Button>
                    <Button onClick={handleSubmit} color="primary">Salvar</Button>
                </DialogActions>
            </Dialog>
        );
    };
    
    export default PlayerFormDialog;