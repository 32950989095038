import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import VotacaoPotes from '../pages/Pots';
import Login from '../pages/Login/Login';
import PageAprove from '../pages/aprove/Aprove';
import PageDashBoard from '../pages/dashbord/Dashboard';
import Estatuto from '../pages/Estatuto';
import ResetPassword from '../pages/ResetPassword';

import { AuthProvider } from '../AuthContext'; 
import { AuthMiddleware } from './AuthMiddleware'; // Importe o AuthMiddleware
import { AuthMiddlewareAdmin } from './AuthMiddlewareAdmin';
import CardGrid from '../pages/CardGrid';
import Squad from '../pages/squads/Squad';
import Confrontos from '../pages/Grupo/Confrontos';
import Politicas from '../pages/Politica';
import PlayArrivedPage from '../pages/arrived/PlayArrivedPage';
import Inscricao from '../pages/Torneio/PRESENTATIONPAGE';
import PageInscricao from '../pages/Torneio/REGISTRATIONPAGE';
import PageQrCode from '../pages/Torneio/CONFIRMATIONPAGE';
import AdminPlayersPage from '../pages/admJogadores/components/AdminPlayersPage';
import Ranking from '../pages/games/RankingPage';


const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Rotas públicas (sem necessidade de autenticação) */}
          <Route path="/" exac element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/squad" element={<Squad/>} />
          <Route path="/estatuto" element={<Estatuto />} />
          <Route path="/confrontos" element={<Confrontos />} />
          <Route path="/privacidade" element={<Politicas />} />
          <Route path="/reset-password/:token" element={<ResetPassword/>} />
          <Route path="/inscricao/" element={<Inscricao/>} />
          <Route path="/ranking/" element={<Ranking/>} />


          {/* Rotas privadas (requerem autenticação) */}
          <Route path="/votacao" element={<AuthMiddleware><VotacaoPotes /></AuthMiddleware>} />
          <Route path="/user/aprovacao" element={<AuthMiddlewareAdmin><PageAprove /></AuthMiddlewareAdmin>}/>
          <Route path="/painel" element={<AuthMiddleware><PageDashBoard /></AuthMiddleware>} />
          <Route path="/ordem-chegada/" element={<AuthMiddleware><PlayArrivedPage /></AuthMiddleware>} />
          <Route path="/confirmarInscricao/" element={<AuthMiddleware><PageInscricao /></AuthMiddleware>} />
          <Route path="/pagamentoInscricao/" element={<AuthMiddleware><PageQrCode /></AuthMiddleware>} />
          <Route path="/adminJogadores" element={<AuthMiddleware><AdminPlayersPage /></AuthMiddleware>} />
            
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
