import React, { useState, useEffect } from 'react';
import api from '../../api';
import {
  StyledDataGrid, StyledCard, DialogTitleStyled,
  DialogContentStyled,
  DialogActionsStyled
} from './Styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const FinancialUserDetails = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [financialData, setFinancialData] = useState([]);
  const [balance, setBalance] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [registerSelect, setRegisterSelect] = useState(null);
  const [severity, setSeverity] = useState('success');
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');



  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('user/showAll');
        setUsers(response.data);
      } catch (error) {
        console.error('Erro ao obter usuários:', error);
        setMessage('Erro ao obter jogadores devedores');
        setSeverity('error');
        setOpenMessage(true);
      }
    };

    fetchUsers();
  }, []);

  const handleOpenDialogDelete = async (id) => {
    setDeleteDialogOpen(true);
    setRegisterSelect(id);
  }

  const handleDeleteRegister = async () => {
    try {
      const response = await api.post('/removeRegister', { id: registerSelect });
      if (response.status == 200) {
         await fetchUserFinancialDetails(selectedUser._id)
        setDeleteDialogOpen(false);
        setMessage('Registro excluído');
        setSeverity('success');
        setOpenMessage(true);

      }
    } catch (error) {
      setMessage('Erro ao excluir registro!');
      setSeverity('error');
      setOpenMessage(true);
    }

  }

  const fetchUserFinancialDetails = async (userId) => {
    try {
      console.log(userId)
      const response = await api.post('/getRecordFinancialPlayer', { user_id: userId });
      console.log(response)
      if (response.data.data.saldo !== undefined && response.data.data.saldo !== null) {
        setBalance(response.data.data.saldo);
      }

      setFinancialData(response.data.data.sortedRecords.map((record, index) => ({
        id: index,
        ...record,
        amount: record.type === 'charge' ? -record.amount : record.amount,
      })));
    } catch (error) {
      console.error('Erro ao obter dados financeiros do usuário:', error);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      fetchUserFinancialDetails(selectedUser._id);
    }
  }, [selectedUser]);

  const financialColumns = [
    { field: '_id', headerName: 'id', width: 1, hide: true },

    { field: 'description', headerName: 'Descrição', width: 200 },
    {
      field: 'amount', headerName: 'Valor', width: 130, type: 'number', renderCell: (params) => {
        const amount = params.row.amount;
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(amount);
        const className = amount < 0 ? 'negative' : 'positive';
        return <span className={className}>{formattedAmount}</span>;
      },
    },
    { field: 'createdAt', headerName: 'Data e Hora', width: 200 },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 20,
      renderCell: (params) => (
        <>

          <IconButton onClick={() => handleOpenDialogDelete(params.row._id)}>
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <StyledCard>
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.nome}
        value={selectedUser}
        onChange={(event, newValue) => setSelectedUser(newValue)}
        renderInput={(params) => <TextField {...params} label="Usuário" variant="outlined" />}
        fullWidth
      />
      {selectedUser && (
        <>
          {balance < 0 ? (<h2 style={{ color: 'red' }}>Saldo do Usuário: R$ R$ {balance.toFixed(2)}</h2>) : (<h2 style={{ color: 'green' }} >Saldo do Usuário: R$ {balance.toFixed(2)}</h2>)}


          <StyledDataGrid
            rows={financialData}
            columns={financialColumns}
            autoHeight
            disableColumnMenu
            pageSizeOptions={[10]}
          />

          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
            <DialogTitleStyled>Confirmar Exclusão</DialogTitleStyled>
            <DialogContentStyled>
              <p>Tem certeza de que deseja excluir ?</p>
            </DialogContentStyled>
            <DialogActionsStyled>
              <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                Cancelar
              </Button>
              <Button onClick={() => handleDeleteRegister()} color="error">
                Excluir
              </Button>
            </DialogActionsStyled>
          </Dialog>
          <Snackbar
            open={openMessage}
            autoHideDuration={6000}
            onClose={() => setOpenMessage(false)}
          >
            <MuiAlert onClose={() => setOpenMessage(false)} severity={severity} elevation={6} variant="filled">
              {message}
            </MuiAlert>
          </Snackbar>
        </>
      )}
    </StyledCard>
  );
};

export default FinancialUserDetails;
