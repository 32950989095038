import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ConfirmationDialog from './ConfirmationDialog';
import PlayerFormDialog from './PlayerFormDialog';
import PlayerCard from './PlayerCard';
import api from '../../../api';
import { PageWrapper, Container, Title, Select, PlayersGrid } from '../styles/AdminPlayersPageStyles';
import Header from '../../../components/Header';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Estilos para os Cards de Estatísticas
const ContainerGlobal = styled.div`
 display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column; /* Centraliza os itens em telas menores */
  }
`;
const StatsCard = styled.div`
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: 150px; /* Largura fixa para os cartões */
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

// Estilos para o Container Principal
const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-right: 20px; /* Espaço entre os cartões e o conteúdo principal */
  @media (max-width: 768px) {
    align-items: center; //Centraliza os itens em telas menores
  flex-direction: row;
  padding-right: 100px;

}
`;

// Estilos para o Card de Torneio
const TournamentCard = styled.div`
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: 200px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

// Estilos para o Container de Torneios
const TournamentsContainer = styled.div`
  margin-top: 20px;
`;

// Modal para Criar Torneio
const TournamentFormDialog = ({ open, onClose, onCreate }) => {
  const [formData, setFormData] = useState({ nome: '', data: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onCreate(formData);
    setFormData({ nome: '', data: '' }); // Resetar form após criação
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Criar Torneio</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nome do Torneio"
          type="text"
          fullWidth
          name="nome"
          value={formData.nome}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Data"
          type="date"
          fullWidth
          name="data"
          value={formData.data}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Criar</Button>
      </DialogActions>
    </Dialog>
  );
};

const AdminPlayersPage = () => {
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState('');
  const [players, setPlayers] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [qtdPago, setQtdpago] = useState(0);
  const [qtdFesta, setQtdFesta] = useState(0);
  const [qtdConvidado, setQtdConvidado] = useState(0);
  const [showTournamentDialog, setShowTournamentDialog] = useState(false);
  const [inscritos, setInscritos] = useState([]);
  

  useEffect(() => {
    async function fetchTournaments() {
      try {
        const response = await api.get('/getTorneios');
        setTournaments(response.data);
        setSelectedTournament(response.data[0]);
      } catch (error) {
        console.error('Erro ao buscar torneios:', error);
      }
    }
    fetchTournaments();
  }, []);

  async function fetchPlayers() {
    try {
      const response = await api.get(`players/torneio/${selectedTournament}`);
      setPlayers(response.data.players);
      setQtdpago(response.data.players.filter(p => p.pago === 'SIM').length);
      setQtdConvidado(response.data.players.filter(p => p.nomeConvidado != '').length);
      setQtdFesta(response.data.players.filter(p => p.festa === 'SIM').length);
      setInscritos(response.data.players)
      response.data.players.map(p=> console.log(p.nomeConvidado)

      )

    } catch (error) {
      console.error('Erro ao buscar jogadores:', error);
    }
  }

  useEffect(() => {
    if (selectedTournament) {
      fetchPlayers();
    }
  }, [selectedTournament]);

  const handleCreateTournament = async (tournament) => {
    try {
      const response = await api.post('/torneio/create', tournament);
      setTournaments([...tournaments, response.data]);
      setShowTournamentDialog(false);
    } catch (error) {
      console.error('Erro ao criar torneio:', error);
    }
  };

  const handleEditTournament = (tournament) => {
    // Implementar a lógica de editar torneio
  };

  const handleDeleteTournament = (tournament) => {
    setConfirmationMessage(`Você realmente deseja excluir o torneio ${tournament.nome}?`);
    setConfirmationAction(() => () => deleteTournament(tournament._id));
    setShowConfirmationDialog(true);
  };

  const deleteTournament = async (id) => {
    try {
      await api.delete(`torneio/${id}`);
      setTournaments(tournaments.filter(tournament => tournament._id !== id));
      setShowConfirmationDialog(false);
    } catch (error) {
      console.error('Erro ao excluir torneio:', error);
    }
  };


  const handleSelectTournament = (e) => {
    setSelectedTournament(e.target.value);
  };

  const handleEditPlayer = (player) => {
    setCurrentPlayer(player);
    setShowFormDialog(true);
  };

  const handleDeletePlayer = (player) => {
    setConfirmationMessage(`Você realmente deseja excluir o jogador ${player.nome}?`);
    setConfirmationAction(() => () => deletePlayer(player._id));
    setShowConfirmationDialog(true);
  };

  const deletePlayer = async (id) => {
    try {
      await api.remove(`players/${id}`);
      setPlayers(players.filter(player => player._id !== id));
      fetchPlayers();
    } catch (error) {
      console.error('Erro ao excluir jogador:', error);
    }
  };

  const handleSavePlayer = async (player) => {
    try {
      if (player._id) {
        await api.put(`players/${player._id}`, player);
      } else {
        const response = await axios.post('players', player);
        player = response.data.player;
      }
      setPlayers(prevPlayers => {
        const existingPlayerIndex = prevPlayers.findIndex(p => p._id === player._id);
        if (existingPlayerIndex !== -1) {
          const updatedPlayers = [...prevPlayers];
          updatedPlayers[existingPlayerIndex] = player;
          return updatedPlayers;
        }
        return [...prevPlayers, player];
      });
      setShowFormDialog(false);
      fetchPlayers();
    } catch (error) {
      console.error('Erro ao salvar jogador:', error);
    }
  };

  const handleCloseFormDialog = () => {
    setShowFormDialog(false);
    setCurrentPlayer(null);
  };

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
    setConfirmationAction(null);
  };

  return (
    <>
      <Header />
      <PageWrapper>
      <ContainerGlobal>

        
        
        <Container>
      <Title>Administração de Jogadores Inscritos</Title>
      <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setShowTournamentDialog(true)}
          >
            Criar Novo Torneio
          </Button>

          <TournamentsContainer>
            <h3>Lista de Torneios</h3>
            {tournaments.map(tournament => (
              <TournamentCard key={tournament._id}>
                <h4>{tournament.nome}</h4>
                <p>Data: {new Date(tournament.data).toLocaleDateString()}</p>
                <Button onClick={() => handleEditTournament(tournament)}>Editar</Button>
                <Button onClick={() => handleDeleteTournament(tournament)}>Excluir</Button>
              </TournamentCard>
            ))}
          </TournamentsContainer>
      <StatsContainer>
      <StatsCard>
            <h3>Total de Jogadores </h3>
            <p>{inscritos.length}</p>
          </StatsCard>
          <StatsCard>
            <h3>Total de Jogadores Pagos</h3>
            <p>{qtdPago}</p>
          </StatsCard>
          <StatsCard>
            <h3>Total de Convidados</h3>
            <p>{qtdConvidado}</p>
          </StatsCard>
          <StatsCard>
            <h3>Total de Jogadores na Festa</h3>
            <p>{qtdFesta}</p>
          </StatsCard>
         
        </StatsContainer>
          {/* <Title>Administração de Jogadores Inscritos</Title> */}
          <Select value={selectedTournament} onChange={handleSelectTournament}>
            <option value="">Selecione um Torneio</option>
            {tournaments.map(tournament => (
              <option key={tournament._id} value={tournament._id}>{tournament.nome}</option>
            ))}
          </Select>
          <PlayersGrid>
            {players.map(player => (
              <PlayerCard
                key={player._id}
                player={player}
                onEdit={handleEditPlayer}
                onDelete={handleDeletePlayer}
              />
            ))}
          </PlayersGrid>
        </Container>

           {/* Modal para Criar Torneio */}
           <TournamentFormDialog
          open={showTournamentDialog}
          onClose={() => setShowTournamentDialog(false)}
          onCreate={handleCreateTournament}
        />

        <PlayerFormDialog
          open={showFormDialog}
          onClose={handleCloseFormDialog}
          player={currentPlayer}
          onSave={handleSavePlayer}
        />

        <ConfirmationDialog
          open={showConfirmationDialog}
          onClose={handleCloseConfirmationDialog}
          onConfirm={confirmationAction}
          message={confirmationMessage}
        />
      </ContainerGlobal>
      </PageWrapper>
    </>
  );
};

export default AdminPlayersPage;