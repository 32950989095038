import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const CardGrid = () => {
  const cardData = Array.from({ length: 36 }, (_, index) => ({
    id: index + 1,
    title: `Card ${index + 1}`,
    content: `Content for Card ${index + 1}`,
  }));

  return (
    <Grid container spacing={2}>
      {cardData.map((card) => (
        <Grid item xs={12} sm={6} md={4} lg={2} key={card.id}>
          <Card>
            <CardContent>
              <Typography variant="h6">{card.title}</Typography>
              <Typography>{card.content}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardGrid;
